import Leap from "leapjs";
import $ from "jquery";

// from: https://glmatrix.net/docs/vec3.js.html#line649
export function dot(a, b) {
  return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

// from glMatrix: https://glmatrix.net/docs/vec3.js.html#line649
export function angle(a, b) {
  const ax = a[0];
  const ay = a[1];
  const az = a[2];
  const bx = b[0];
  const by = b[1];
  const bz = b[2];
  const mag1 = Math.sqrt(ax * ax + ay * ay + az * az);
  const mag2 = Math.sqrt(bx * bx + by * by + bz * bz);
  const mag = mag1 * mag2;
  const cosine = mag && dot(a, b) / mag;
  return Math.acos(Math.min(Math.max(cosine, -1), 1));
}

/**
 * Hands 的屏幕位置计算
 */
export function leapToScene(hand, frame) {
  // 获取手掌稳定位置
  const stabledPalmPos = hand.stabilizedPalmPosition;
  // const indexFingerPos = hand.pointables[1].stabilizedTipPosition;
  // const thumbFingerPos = hand.pointables[0].stabilizedTipPosition;
  // const [ix, iy] = indexFingerPos;
  // const [tx, ty] = thumbFingerPos;
  // const centerPos = [(tx - ix) / 2 + ix, (ty - iy) / 2 + iy];
  return {
    palmPos: normalizePoint(stabledPalmPos, frame),
    // indexFingerPos: normalizePoint(indexFingerPos, frame),
  };
}

export function normalizePoint(pos, frame) {
  // 手部交互范围/区域
  const ibox = frame.interactionBox;
  // 归一化交互位置
  const nPos = ibox.normalizePoint(pos);
  // 映射到屏幕范围
  const wWidth = window.innerWidth;
  const wHeight = window.innerHeight;
  // 光标位置 = 视口范围 * 归一化交互位置
  const x = wWidth * nPos[0];
  const y = wHeight * (1 - nPos[1]);
  return [x, y];
}

/**
 * 食指与拇指之间距离计算
 */
export function distanceBetweenThumbIndex(hand) {
  // tipPosition：tip = 指尖，position = 相对于原点的位置
  return Leap.vec3.distance(
    hand.pointables[0].tipPosition,
    hand.pointables[1].tipPosition
  );
}

/**
 * 识别 pointing 手势方法
 */
export function calPointingGesture(hand) {
  const [thumb, index, middle, ring, pinky] = hand.fingers;
  // meta 掌根骨、proxi 近端指骨、inter 中间指骨
  const [indexMeta, indexProxi, indexInter] = index.bones;

  // a: angle 确保夹角近乎为 0 或者 近乎同向平行状态
  const a1 =
    (angle(indexInter.direction(), indexProxi.direction()) * 180) / Math.PI;
  const a2 =
    (angle(indexMeta.direction(), indexProxi.direction()) * 180) / Math.PI;

  // 确保手指伸直，不容易误触
  return (
    !thumb.extended &&
    !middle.extended &&
    !ring.extended &&
    !pinky.extended &&
    a1 < 25 &&
    a2 < 25
  );
}

export function calDoublePointingGesture(hand) {
  const [thumb, index, middle, ring, pinky] = hand.fingers;
  // meta 掌根骨、proxi 近端指骨、inter 中间指骨
  const [indexMeta, indexProxi, indexInter] = index.bones;
  const [midMeta, midProxi, midInter] = middle.bones;
  // a: angle 确保夹角近乎为 0 或者 近乎同向平行状态
  const a1 =
    (angle(indexInter.direction(), indexProxi.direction()) * 180) / Math.PI;
  const a2 =
    (angle(indexMeta.direction(), indexProxi.direction()) * 180) / Math.PI;
  const a3 =
    (angle(midInter.direction(), midProxi.direction()) * 180) / Math.PI;
  const a4 = (angle(midMeta.direction(), midProxi.direction()) * 180) / Math.PI;
  // 确保手指伸直，不容易误触
  return (
    !thumb.extended &&
    !ring.extended &&
    !pinky.extended &&
    a1 < 25 &&
    a2 < 25 &&
    a3 < 25 &&
    a4 < 25
  );
}

/**
 * 根据 坐标 获取 d
 * @param pathList number[][]
 * @return M 1,2 L3,4 5,6...
 * */
export function getPathD(pathList) {
  const last = pathList.slice(1).reduce((total, point) => {
    total.push(`L${point[0]},${point[1]}`);
    return total;
  }, []);
  return `M${pathList.slice(0, 1)}${last.join("")}`;
}

/** 创建 svg 元素 */
export function $s(elem) {
  return $(document.createElementNS("http://www.w3.org/2000/svg", elem));
}
