import * as d3 from "d3";
import { vec2 } from "gl-matrix";
import { AxisInfo } from "../../components/types";
import { getAngle, getScaleOffset } from "../axis";
import { getTransformMatrix, getSecTransVec3 } from "./trans";
import { ELEMENT } from "../../components/constant";
import { calBBox } from "./trans";
import { getMapping } from "./scatter";

/**
 * 获取散点图（高亮框）数据
 * @param v1 轴信息（vector）
 * @param v2 轴信息（vector）
 */
export function getTransBarInfo(
  v1: AxisInfo | any,
  v2: AxisInfo | any,
  mapping?: Record<string, any>
) {
  let polyPoints;
  if (v2.type === ELEMENT.CIRCULAR_AXIS || v1.type === ELEMENT.CIRCULAR_AXIS) {
    // 检测谁是 circularAxis，谁是 lineAxis
    const lineAxis = v2.type === ELEMENT.CIRCULAR_AXIS ? v1 : v2;
    const circularAxis = v2.type === ELEMENT.CIRCULAR_AXIS ? v2 : v1;

    const pureData = lineAxis.data.map((d: any) => d.value);
    const centerPos = circularAxis.startPos;
    const rotateAngle = getAngle(lineAxis.startPos, lineAxis.endPos);
    const rotateArc = (rotateAngle * Math.PI) / 180; // 极坐标旋转的 arc
    const radiusScale = lineAxis.scale;
    const xScale = circularAxis.scale;

    const len = lineAxis.len;
    const rawPoints = [
      [-len, -len, 1],
      [len, -len, 1],
      [len, len, 1],
      [-len, len, 1],
    ];
    polyPoints = calBBox(rotateArc, centerPos, rawPoints).map((d) => [
      d.x,
      d.y,
    ]);

    // 弧度生成器
    const arc_generator = d3.arc();

    // path 的 d 生成器
    const angle_data = d3.pie().sort(null);

    let barPaths = [] as any;
    try {
      // startAngle 默认为 0，endAngle 默认为 Math.PI * 2
      // rotation = -90 时，才相当于 startAngle = 0
      // 因此需要 + Math.PI/2 与 rotation 抵消，让 startAngle = 0，得以正常画 arc
      barPaths = angle_data(pureData).map((angle, index) => {
        arc_generator
          .startAngle(
            xScale(circularAxis.data[index].value) + rotateArc + Math.PI / 2
          )
          .endAngle(
            xScale(circularAxis.data[index].value) +
              xScale.bandwidth() +
              rotateArc +
              Math.PI / 2
          )
          .innerRadius(5)
          .outerRadius(radiusScale(angle.value as any));
        return arc_generator(angle as any);
      });
    } catch (err) {
      console.warn(
        "please choose「string」type dimension for generate circular axis！"
      );
    }

    return {
      coordType: "polar",
      polyPoints,
      bbox: calBBox(rotateArc, centerPos, rawPoints),
      barElements: { centerPos, barPaths, ...getMapping(mapping) },
    };
  }
  // 准备正交数据
  const orthoData1 = v1.data.map(
    (d: any) => v1.scale(d.value) + getScaleOffset(v1.scale)
  );
  const orthoData2 = v2.data.map(
    (d: any) => v2.scale(d.value) + getScaleOffset(v2.scale)
  );

  // 准备柱形元素的坐标数组
  const barOrthoPoints = orthoData1.map((item: any, index: number) => {
    return {
      start: [orthoData2[index], orthoData1[index], 1],
      end: [orthoData2[index], 0, 1],
    };
  });

  // 准备高亮框的坐标数组[][]
  const polyPadding = 0;
  const polyOrthoPoints = [
    [0 - polyPadding, 0 - polyPadding, 1],
    [v2.len + polyPadding, 0 - polyPadding, 1],
    [v2.len + polyPadding, v1.len + polyPadding, 1],
    [0 - polyPadding, v1.len + polyPadding, 1],
  ];

  // #7: fix bug for parallel axes
  const realV1 = [v1.endPos.x - v1.startPos.x, v1.endPos.y - v1.startPos.y];
  const realV2 = [v2.endPos.x - v2.startPos.x, v2.endPos.y - v2.startPos.y];
  const v = vec2.cross([0, 0, 0], realV1 as any, realV2 as any);
  if (v[2] === 0) return { polyPoints: null, scatterPoints: null };

  const { shear_matrix, firstTranslation, secTranslation } = getTransformMatrix(
    v2,
    v1
  );

  const barPaths = barOrthoPoints.map((item: any) => {
    const secTransVec3forStartPoint = getSecTransVec3(
      item.start,
      shear_matrix,
      firstTranslation,
      secTranslation
    );
    const secTransVec3forEndPoint = getSecTransVec3(
      item.end,
      shear_matrix,
      firstTranslation,
      secTranslation
    );
    const info = {
      start: secTransVec3forStartPoint,
      end: secTransVec3forEndPoint,
      vec: secTransVec3forStartPoint[2],
    };
    return info;
  });

  polyPoints = [];
  for (const item of polyOrthoPoints) {
    const secTransVec3 = getSecTransVec3(
      item,
      shear_matrix,
      firstTranslation,
      secTranslation
    );
    polyPoints.push(secTransVec3);
  }

  return {
    coordType: "cartesian",
    polyPoints,
    bbox: polyPoints.map((p) => ({ x: p[0], y: p[1] })),
    barElements: {
      barPaths,
      ...getMapping(mapping),
    },
  };
}
