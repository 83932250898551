import { AxisInfo } from "../../components/types";
import { vec3, mat3 } from "gl-matrix";
import { getScaleOffset } from "../axis";
import { getMapping } from "./scatter";

export function getTransParallelInfo(
  v1: AxisInfo | any,
  v2: AxisInfo | any,
  mapping?: Record<string, any>
) {
  // 变形原理：先剪切 再平移

  // 准备正交数据
  const orthoData1 = v1.data.map(
    (d: any) => v1.scale(d.value) + getScaleOffset(v1.scale)
  );
  const orthoData2 = v2.data.map(
    (d: any) => v2.scale(d.value) + getScaleOffset(v2.scale)
  );

  const { x: x1, y: y1 } = v1.startPos;
  const { x: x2, y: y2 } = v1.endPos;
  const { x: x3, y: y3 } = v2.startPos;
  const { x: x4, y: y4 } = v2.endPos;

  // 准备变换矩阵
  const shear_matrix1 = mat3.fromValues(
    1,
    0,
    0,
    v1.len === 0 ? 0 : (x2 - x1) / v1.len,
    v1.len === 0 ? 0 : (y2 - y1) / v1.len,
    0,
    0,
    0,
    1
  );
  const shear_matrix2 = mat3.fromValues(
    1,
    0,
    0,
    v2.len === 0 ? 0 : (x4 - x3) / v2.len,
    v2.len === 0 ? 0 : (y4 - y3) / v2.len,
    0,
    0,
    0,
    1
  );
  const trans_matrix1 = mat3.fromTranslation(mat3.create(), [x1, y1]);
  const trans_matrix2 = mat3.fromTranslation(mat3.create(), [x3, y3]);

  // 变换平行坐标元素的数据
  const lines = orthoData2.map((item: any, i: number) => {
    const pathDot1 = [0, orthoData1[i], 1];
    const pathDot2 = vec3.transformMat3(
      vec3.create(),
      pathDot1 as any,
      shear_matrix1
    );
    const pathDot3 = vec3.transformMat3(vec3.create(), pathDot2, trans_matrix1);

    const pathDot4 = [0, orthoData2[i], 1];
    const pathDot5 = vec3.transformMat3(
      vec3.create(),
      pathDot4 as any,
      shear_matrix2
    );
    const pathDot6 = vec3.transformMat3(vec3.create(), pathDot5, trans_matrix2);

    // 直线的起点和终点
    return [pathDot3, pathDot6];
  });

  // 平行坐标图的高亮框数据
  const polyPoints = [
    [x1, y1],
    [x2, y2],
    [x4, y4],
    [x3, y3],
  ];

  return {
    parallelElements: {
      lines,
      ...getMapping(mapping),
    },
    bbox: polyPoints.map((p) => ({ x: p[0], y: p[1] })),
    polyPoints,
  };
}
