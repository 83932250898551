import { HIGHLIGHT_COLOR } from "../../constant";
import { useRef, useState } from "react";
import { useContextMappingMenu, useSwitchPlot } from "../../../utils/elements";
import { PlotProps } from "../../types";
import "../../canvas/index.css";
import { getPointsForPolygon } from "../../../utils/transform/trans";

/** 柱形图的配置属性 */
export type BarProps = PlotProps & {
  /** 柱形图元素数据 */
  barElements: any;
  /** 元素的坐标系类型 */
  coordType: string; // 'polar' or 'cartesian'
};

export const Bar: React.FC<BarProps> = ({
  id,
  coordType,
  barElements,
  polyPoints,
  selected,
  setVisualMappingInfo,
  canvasData,
  setCanvasData,
}) => {
  // console.log("render scatter");
  const [activeState, setActiveState] = useState(false);
  const selectedState = activeState || selected;
  const plot = useRef(null);

  const points = getPointsForPolygon(polyPoints);

  useContextMappingMenu(plot, id, setVisualMappingInfo, canvasData);

  useSwitchPlot(plot, id, canvasData, setCanvasData);

  return (
    <g className="bar-group">
      {points && (
        <polygon
          ref={plot}
          onMouseEnter={() => {
            setActiveState(true);
          }}
          onMouseLeave={() => {
            setActiveState(false);
          }}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
          strokeWidth={2}
          points={points}
        ></polygon>
      )}
      {coordType === "cartesian" &&
        barElements.barPaths.map((point: any, index: number) => {
          return (
            <line
              key={index}
              className="view-element"
              x1={point.start[0]}
              y1={point.start[1]}
              x2={point.end[0]}
              y2={point.end[1]}
              stroke={barElements.mappingColors?.[index] || "steelblue"}
              strokeWidth={20}
            />
          );
        })}
      {coordType === "polar" && (
        <g
          transform={`translate(${barElements.centerPos.x}, ${barElements.centerPos.y}) 
        `}
        >
          {barElements.barPaths.map((d: any, i: number) => {
            return (
              <path
                key={i}
                className="view-element"
                d={d}
                fill={barElements.mappingColors?.[i] || "#000"}
                stroke={"#eee"}
                strokeWidth={1}
              />
            );
          })}
        </g>
      )}
    </g>
  );
};
