/** 轴的端点半径 */
export const TERMINAL_CIRCLE_RADIUS = 15;
/** 散点图半径 */
export const SCATTER_CIRCLE_RADIUS = 6;
/** 菜单项的宽度 */
export const SCHEMA_ITEM_WIDTH = 287;

/** 元素类型 */
export const ELEMENT = {
  LINE_AXIS: "lineAxis",
  CIRCULAR_AXIS: "circularAxis",
  PLOT: "plot",
  SCATTER: "scatter",
  PARALLEL: "Parallel",
  BAR: "bar",
  LINE: "line",
  PIE: "pie",
  SELECTING_BOX: "selecting-box",
  SELECTED_BOX: "selected-box",
};

/** 轴端点 */
export const TERMINAL = {
  START: "start",
  END: "end",
};

/** 高亮色 */
export const HIGHLIGHT_COLOR = "#6ac1dc";

/** 比例尺的类型 */
export const SCALE_TYPE = {
  LINEAR: "linear",
  ORDINAL: "ordinal", // 序数比例尺， 输入输出都是离散的
  BAND: "band", //分段比例尺，输入是离散的，输出是连续的
};

/** 模式 与 工具栏 */
export const MODE = {
  SELECT: "Select",
  LINE_AXIS: "LineAxis",
  CIRCULAR_AXIS: "CircularAxis",
  SCATTER: "Scatter",
  PARALLEL: "Parallel",
  BAR: "Bar",
  LINE: "Line",
  PIE: "Pie",
};

/** 交互类型 */
export const INTERACTION_TYPE = {
  OPEN_DIM_MENU: "open-dimension-menu",
};
