import React, { createContext, useState, useContext } from "react";

/** 定义上下文 provider */
const ModeContext = createContext(
  {} as {
    mode: string;
    setMode: React.Dispatch<React.SetStateAction<string>>;
  }
);

export const ModeProvider = ({ children }: { children: JSX.Element }) => {
  const [mode, setMode] = useState<string>("LineAxis");
  return (
    <ModeContext.Provider value={{ mode, setMode }}>
      {children}
    </ModeContext.Provider>
  );
};

/** 定义上下文 自定义hook */
export const useMode = () => useContext(ModeContext);

// HOC: memo with context 暂未使用
export function memoComponentWithContext<T, C>(
  WrapperComponent: React.ComponentType<T>,
  context: () => C
) {
  return function (props: Omit<T, keyof C>) {
    const contextProps = context();
    const newProps = { ...props, ...contextProps } as unknown as T;
    return <WrapperComponent {...newProps}></WrapperComponent>;
  };
}
