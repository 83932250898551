import { HIGHLIGHT_COLOR } from "../../constant";
import { useRef, useState } from "react";
import { Point, PlotProps } from "../../types";
import { useContextMappingMenu, useSwitchPlot } from "../../../utils/elements";
import { getPointsForPolygon } from "../../../utils/transform/trans";

/** 折线图的配置属性 */
export type LineProps = PlotProps & {
  /** 折线图元素数据 */
  linePoints: any;
  /** 元素的坐标系类型 */
  coordType: string; // 'polar' or 'cartesian'
};

export const Line: React.FC<LineProps> = ({
  id,
  linePoints,
  polyPoints,
  selected,
  coordType,
  setVisualMappingInfo,
  canvasData,
  setCanvasData,
}) => {
  // console.log("render line");
  const [activeState, setActiveState] = useState(false);
  const selectedState = activeState || selected;
  const plot = useRef(null);

  const points = getPointsForPolygon(polyPoints);

  let closed;
  if (coordType === "cartesian") {
    closed = "";
  }
  if (coordType === "polar") {
    closed = "Z";
  }

  const polyPath = linePoints?.scatters?.reduce(
    (total: number[], point: Point) => {
      total.push(point.x);
      total.push(point.y);
      return total;
    },
    []
  );

  useContextMappingMenu(plot, id, setVisualMappingInfo, canvasData);

  useSwitchPlot(plot, id, canvasData, setCanvasData);

  return (
    <g className="line-group">
      {points && (
        <polygon
          ref={plot}
          onMouseEnter={() => {
            setActiveState(true);
          }}
          onMouseLeave={() => {
            setActiveState(false);
          }}
          strokeWidth={2}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
          points={points}
        ></polygon>
      )}
      {polyPath && (
        <>
          <g
            transform={
              linePoints?.centerPos &&
              `translate(${linePoints?.centerPos?.x}, ${linePoints?.centerPos?.y})`
            }
          >
            <path
              d={
                `M ${polyPath.slice(0, 2).join(" ")} L ${polyPath
                  .slice(2)
                  .join(" ")}` + closed
              }
              fill={"transparent"}
              stroke={"steelblue"}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            />
          </g>
        </>
      )}
    </g>
  );
};
