import { memo, useCallback, useEffect, useRef, MutableRefObject } from "react";
import { vec3, mat3 } from "gl-matrix";
import { MiniPlot } from "../elements/miniPlot";
import { useMode } from "../../context/canvas";
import { ELEMENT, MODE, SCHEMA_ITEM_WIDTH } from "../constant";
import { checkCollision, closeMappingMenu } from "../../utils/canvas";
import { Point } from "../../../src/components/types";
import { changeAxisInfo, setAllElemsUnselected } from "../../utils/axis";
import { updatePlotInfo } from "../../utils/plot";
import "./index.css";
import { VisualMappingInfo } from "../visualMappingMenu";

export interface SchemaMenuProps {
  /** 画布的数据 */
  canvasData: any[];
  /** 设置画布的数据 */
  setCanvasData: (d: any) => void;
  /** 数据文件名称列表 */
  allFieldsData: FieldInfo[];
  /** 当前 field 字段的数据 */
  currFieldData: FieldInfo;
  /** 设置当前 field 字段的数据 */
  setCurrFieldData: (v: FieldInfo) => void;
  /** 设置列表 fields 所有数据 */
  setAllFieldsData: (v: SchemaMenuProps["allFieldsData"]) => void;
  /** 全局状态 */
  globalStatus: MutableRefObject<Record<string, any>>;
  /** 设置映射 */
  visualMappingInfo: VisualMappingInfo;
  /** 设置节点克隆数据 */
  setCloneItemInfo: (d: any) => void;
  /** 节点克隆数据 */
  cloneItemInfo: any;
  setVisualMappingInfo: (
    info: VisualMappingInfo | ((info: VisualMappingInfo) => VisualMappingInfo)
  ) => void;
}

export type FieldInfo = {
  /** 文件名称 */
  fileName: string;
  /** 数据字段名称 */
  name: string;
  /** 数据字段类型*/
  type: string;
  /** 数据字段数值 */
  data: { value: number | string }[];
  /** 是否被 hover */
  active?: boolean;
  /** 是否被点击选择 */
  selected?: boolean;
};

export function getBackgroundColor(selected: boolean, active: boolean) {
  if (selected) return "#e9f6fe";
  if (active) return "#f5f5f5";
  return "#fff";
}

/** 获取 维度某项的 包围盒坐标数组 */
export function getListItemBBox(transPos: Point) {
  // width: 298, height: 75
  const rawBBox = [
    [0, 0, 1],
    [298, 0, 1],
    [298, 75, 1],
    [0, 75, 1],
  ];
  const translateMatrix = mat3.fromTranslation(mat3.create(), [
    transPos.x,
    transPos.y,
  ]);
  return rawBBox
    ?.map((point: any) => {
      return vec3.transformMat3(vec3.create(), point as any, translateMatrix);
    })
    .map((d) => ({ x: d[0], y: d[1] }));
}

export const SchemaMenu: React.FC<SchemaMenuProps> = memo(
  ({
    allFieldsData,
    currFieldData,
    setCurrFieldData,
    setAllFieldsData,
    canvasData,
    setCanvasData,
    globalStatus,
    visualMappingInfo,
    cloneItemInfo,
    setCloneItemInfo,
    setVisualMappingInfo,
  }) => {
    const schemaMenu = useRef(null);
    const { mode } = useMode();
    // const [listItemInfo, setListItemInfo] = useState<any>();
    const mousePos = useRef({
      sx: 0,
      sy: 0,
      x: 0,
      y: 0,
      itemBBox: [] as Point[],
    });
    const { dataOverviewMenuShow, dataOverviewMenuPos } = visualMappingInfo;

    const handleClick = useCallback(
      (e: any) => {
        const path = e.path ? e.path : e.nativeEvent.path;
        const schemaItem = path.filter(
          (dom: any) => dom.className === "schema-item"
        )[0];

        const index = Number(schemaItem?.getAttribute("data-key"));
        if (Number.isNaN(index)) return;
        if (
          mode === MODE.CIRCULAR_AXIS &&
          allFieldsData[index].type === "number"
        ) {
          alert("please choose string type dimension for circular axis!");
          return;
        }
        // 更新选中的数据维度
        const infos = allFieldsData.map((item) => ({
          ...item,
          selected: false,
        }));
        infos[index].selected = true;

        setAllFieldsData(infos);
        setCurrFieldData(infos[index]);
      },
      [allFieldsData, setCurrFieldData, setAllFieldsData, mode]
    );

    const handleClickEnd = useCallback(
      (e: any) => {
        closeMappingMenu(setVisualMappingInfo);
      },
      [setVisualMappingInfo]
    );

    const handleMouseEnter = (index: number) => {
      const infos = [...allFieldsData];
      infos[index].active = true;
      setAllFieldsData(infos);
    };

    const handleMouseLeave = (index: number) => {
      const infos = [...allFieldsData];
      infos[index].active = false;
      setAllFieldsData(infos);
    };

    const handleDragStart = useCallback(
      (e: any) => {
        e.preventDefault();

        const path = e.path ? e.path : e.nativeEvent.path;
        const schemaItem = path.filter(
          (dom: any) => dom.className === "schema-item"
        )[0];

        let newCanvasData = [...canvasData];

        const index = Number(schemaItem?.getAttribute("data-key"));
        if (Number.isNaN(index)) return;

        // 如果正在 pinching schema item，就不操纵轴
        globalStatus.current.isSelectSchemaItem = true;

        handleClick(e);

        setCloneItemInfo((prev: any) => ({ ...prev, show: true }));

        // 拿到 listItem 的包围盒数据
        // refference: https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element
        const bodyRect = document.body.getBoundingClientRect();

        const { x, y } = schemaItem.getBoundingClientRect();
        mousePos.current = {
          sx: e.pageX,
          sy: e.pageY,
          x: x - bodyRect.left,
          y: y - bodyRect.top,
          itemBBox: [],
        };

        const handleDraging = (e: any) => {
          e.preventDefault();

          const { pageX: ex, pageY: ey } = e;
          const offsetX = ex - mousePos.current.sx;
          const offsetY = ey - mousePos.current.sy;

          // 与鼠标坐标计算，考虑偏移，转换成画布实际坐标
          const listItemBBox = getListItemBBox({
            x: mousePos.current.x + offsetX,
            y: mousePos.current.y + offsetY,
          });
          mousePos.current = { ...mousePos.current, itemBBox: listItemBBox };

          // 获取 axes 的 boundingBox
          const allAxes = newCanvasData.filter(
            (item) =>
              item.type === ELEMENT.LINE_AXIS ||
              item.type === ELEMENT.CIRCULAR_AXIS
          );

          newCanvasData = setAllElemsUnselected(newCanvasData);
          checkCollision(listItemBBox as any, allAxes, (isCollided, axis) => {
            if (isCollided) {
              const axisIndex = newCanvasData.findIndex(
                ({ id }) => id === axis.id
              );
              newCanvasData[axisIndex] = {
                ...axis,
                selected: true,
              };
            }
          });
          setCanvasData(newCanvasData);

          setCloneItemInfo((prev: any) => ({ ...prev, bbox: listItemBBox }));
        };

        const handleDragEnd = () => {
          e.preventDefault();

          const listItemBBox = mousePos.current?.itemBBox;
          // 获取 axes 的 boundingBox
          const allAxes = newCanvasData.filter(
            (item) =>
              item.type === ELEMENT.LINE_AXIS ||
              item.type === ELEMENT.CIRCULAR_AXIS
          );

          // 当前数据
          const infos = allFieldsData.map((item) => ({
            ...item,
            selected: false,
          }));
          infos[index].selected = true;

          // 碰撞检测: 让维度 item 与 轴 碰撞，成功则替换数据
          checkCollision(listItemBBox, allAxes, (isCollided, axis) => {
            if (isCollided) {
              const axisIndex = newCanvasData.findIndex(
                ({ id }) => id === axis.id
              );
              axis.label = infos[index].name;
              axis.data = infos[index].data;
              let axisInfo = changeAxisInfo(axis);
              newCanvasData[axisIndex] = {
                ...axis,
                ...axisInfo,
              };
              newCanvasData = newCanvasData.map((d) => ({
                ...d,
                selected: false,
              }));
              updatePlotInfo(newCanvasData, axis.id);
            }
          });

          // clone 节点消失
          setCloneItemInfo((prev: any) => ({
            ...prev,
            show: false,
            bbox: [{ x: -500, y: -500 }],
          }));

          setCanvasData(newCanvasData);

          globalStatus.current.isSelectSchemaItem = false;

          document.removeEventListener("mousemove", handleDraging);
          document.removeEventListener("mouseup", handleDragEnd);
          document.removeEventListener("handpinching", handleDraging);
          document.removeEventListener("handpinchend", handleDragEnd);
        };
        document.addEventListener("mousemove", handleDraging);
        document.addEventListener("mouseup", handleDragEnd);
        document.addEventListener("handpinching", handleDraging);
        document.addEventListener("handpinchend", handleDragEnd);
      },
      [
        allFieldsData,
        canvasData,
        setCanvasData,
        handleClick,
        globalStatus,
        setCloneItemInfo,
      ]
    );

    useEffect(() => {
      const menu = schemaMenu.current;

      //@ts-ignore
      menu?.addEventListener("handpinchstart", handleDragStart);
      //@ts-ignore
      menu?.addEventListener("handpointing", handleClick);
      //@ts-ignore
      menu?.addEventListener("handpointend", handleClickEnd);
      //@ts-ignore
      menu?.addEventListener("mouseup", handleClickEnd);
      return () => {
        //@ts-ignore
        menu?.removeEventListener("handpinchstart", handleDragStart);
        //@ts-ignore
        menu?.removeEventListener("handpointing", handleClick);
        //@ts-ignore
        menu?.removeEventListener("handpointend", handleClickEnd);
        //@ts-ignore
        menu?.removeEventListener("mouseup", handleClickEnd);
      };
    }, [
      allFieldsData,
      mode,
      setAllFieldsData,
      setCurrFieldData,
      handleClick,
      handleDragStart,
      globalStatus,
      handleClickEnd,
    ]);

    return (
      <>
        {
          <ul
            ref={schemaMenu}
            id="schema-menu"
            className="schema-list"
            style={{
              width: SCHEMA_ITEM_WIDTH * 3 + 10,
              visibility: dataOverviewMenuShow ? "visible" : "hidden",
              left: dataOverviewMenuPos[0] + "px",
              top: dataOverviewMenuPos[1] + "px",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "inherit",
            }}
          >
            {allFieldsData.map(
              ({ name, type, data, selected, active }, index) => {
                return (
                  <li
                    key={index}
                    data-key={index}
                    className="schema-item"
                    onMouseDown={(e) => {
                      handleDragStart(e);
                      // handleClick(e);
                    }}
                    onMouseEnter={() => {
                      handleMouseEnter(index);
                    }}
                    onMouseLeave={() => {
                      handleMouseLeave(index);
                    }}
                    style={{
                      width: 287,
                      backgroundColor: getBackgroundColor(
                        selected as boolean,
                        active as boolean
                      ),
                    }}
                  >
                    <h4>
                      {name}
                      <br></br>({type})
                    </h4>
                    {/* <div className="mini-plot"> */}
                    {/* <MiniPlot width={100} height={50} data={data} /> */}
                    {/* </div> */}
                  </li>
                );
              }
            )}
          </ul>
        }
        {
          /** 相当于clone dom */
          cloneItemInfo?.bbox && (
            <div
              className="clone-schema-item"
              style={{
                display: cloneItemInfo.show ? "flex" : "none",
                backgroundColor: "#fff",
                position: "absolute",
                boxShadow: "0 0 10px 2px rgba(0,0,0,.1)",
                left: cloneItemInfo.bbox[0].x,
                top: cloneItemInfo.bbox[0].y,
                border: "1px solid #eee",
              }}
            >
              <div className="text-info">
                <h4>{currFieldData.name}</h4>
                <span>{currFieldData.type}</span>
              </div>
              <div className="mini-plot">
                <MiniPlot width={100} height={50} data={currFieldData.data} />
              </div>
            </div>
          )
        }
      </>
    );
  }
);
