import { memo, MutableRefObject } from "react";
import { Radio, RadioChangeEvent, Button } from "antd";
import {
  DeleteOutlined,
  RollbackOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { useMode } from "../../context/canvas";
import { ELEMENT, MODE } from "../constant";
import { FieldInfo } from "../schemaMenu";
import FileSaver from "file-saver";
import "./index.css";

type ToolMenuProps = {
  /** 当前的数据信息 */
  currFieldData: FieldInfo;
  /** 画布数据 */
  canvasData: Record<string, any>[];
  /** 设置画布数据 */
  setCanvasData: (d: Record<string, any>[]) => void;
  /** 全局状态 */
  globalStatus: MutableRefObject<Record<string, any>>;
};

export const ToolMenu: React.FC<ToolMenuProps> = memo(
  ({ canvasData, setCanvasData, currFieldData, globalStatus }) => {
    const { mode, setMode } = useMode();

    const handleModeChange = (e: RadioChangeEvent) => {
      if (e.target.value === MODE.CIRCULAR_AXIS) {
        if (currFieldData.type === "number") {
          alert("please choose string type dimension for circular axis!");
          return;
        }
      }
      setMode(e.target.value);
    };

    const handleDelete = () => {
      setCanvasData(
        canvasData.filter(
          (datum) =>
            datum?.selected !== true && datum?.type !== ELEMENT.SELECTED_BOX
        )
      );
    };

    const handleUndo = () => {
      const newData = [...canvasData];
      newData.pop();
      setCanvasData(newData);
    };

    const handleExport = () => {
      const csvString = [
        ["date", "time", "interaction-type"],
        ...globalStatus.current.logData,
      ]
        .map((row) => row.join(","))
        .join(`\n`);

      const exportContent = `\uFEFF`;
      const blob = new Blob([exportContent + csvString], {
        type: "text/plain;charset=utf-8",
      });
      FileSaver.saveAs(blob, "save.csv");
    };

    return (
      <div
        style={{
          position: "absolute",
          top: "72px",
          left: "50%",
          display: "flex",
          transform: "translateX(-50%)",
        }}
      >
        <Radio.Group value={mode} onChange={handleModeChange}>
          <Radio.Button value="Select" style={{ marginRight: "16px" }}>
            <span className="tool-icon iconfont icon-yidong-03-01"></span>
          </Radio.Button>
          <Radio.Button value="LineAxis">
            <span className="tool-icon iconfont icon-icon-04"></span>
          </Radio.Button>
          <Radio.Button value="CircularAxis" style={{ marginRight: "16px" }}>
            <span className="tool-icon iconfont icon-icon-05"></span>
          </Radio.Button>
          <Radio.Button value="Scatter">
            <span className="tool-icon iconfont icon-icon-06"></span>
          </Radio.Button>
          <Radio.Button value="Parallel">
            <span className="tool-icon iconfont icon-icon-07"></span>
          </Radio.Button>
          <Radio.Button value="Bar">
            <span className="tool-icon iconfont icon-icon-09"></span>
          </Radio.Button>
          <Radio.Button value="Line">
            <span className="tool-icon iconfont icon-icon-08"></span>
          </Radio.Button>
          {/* <Radio.Button value="Area">Area</Radio.Button> */}
          {/* <Radio.Button value="Heatmap">Heatmap</Radio.Button> */}
          <Radio.Button value="Pie" style={{ marginRight: "16px" }}>
            <span className="tool-icon iconfont icon-icon-10"></span>
          </Radio.Button>
        </Radio.Group>

        <Button
          style={{ marginRight: "16px" }}
          shape="circle"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
        />
        <Button
          style={{ marginRight: "16px" }}
          shape="circle"
          icon={<RollbackOutlined onClick={handleUndo} />}
        />
        <Button
          style={{ marginRight: "16px" }}
          shape="circle"
          icon={<FileOutlined onClick={handleExport} />}
        />
      </div>
    );
  }
);
