import "./App.css";
import "antd/dist/antd.css";
import { MainView } from "./pages/mainView";
// import { TestListPage } from './pages/test-list';

function App() {
  return (
    <div className="App">
      {/* <TestListPage /> */}
      <MainView />
    </div>
  );
}

export default App;
