import $ from "jquery";
import { cursorColor, cursorColorRGB } from "./constant";
import {
  leapToScene,
  distanceBetweenThumbIndex,
  calPointingGesture,
  calDoublePointingGesture,
  getPathD,
  $s,
} from "../utils";
import { DollarRecognizer } from "../lib/dollar-one";

/**
 *  光标类
 */
export class Pointer {
  /** 模拟 touch 事件 */
  static targetTouches = {}; // 存储目标上的 touch

  static changedTouches = {}; // 存储在变更的 touch

  constructor(props) {
    const { id } = props;
    /** 光标 id */
    this.id = id;
    this.index = null;
    /** 光标绑定的手类型：left or right */
    this.handType = "";
    /** 光标位置 */
    this.currX = 0;
    this.currY = 0;
    /** 食指位置 */
    this.indexFingerX = 0;
    this.indexFingerY = 0;
    /** 光标节点 */
    this.largeCursor = null;
    this.smallCursor = null;
    this.wave = null;
    this.cursorTip = null; // 光标的提示文字
    /** 识别状态 */
    this.isPinching = false;
    this.isPointing = false;
    this.isDoublePointing = false;
    this.isOpen = false;
    /** 存储 dbpointing 的轨迹 */
    this.pathList = [];
    this.pathCursor = null;
    this.pathContainer = null;
    /** 轨迹识别器 */
    this.recognizer = new DollarRecognizer();
    this.init();
  }

  /**
   * init cursor
   */
  init() {
    this.createCursor();
  }

  /**
   * create cursor and add cursor to body
   */
  createCursor() {
    this.createLargeCursor();
    this.createSmallCursor();
  }

  /**
   * 创建大光标
   */
  createLargeCursor() {
    this.largeCursor = $("<span></span>");
    this.largeCursor.css({
      border: `solid 1px ${cursorColor}`,
      "border-radius": "50%",
      position: "absolute",
      zIndex: "255",
      opacity: 1,
      display: "flex",
      "justify-content": "center",
      "align-items": "center",
    });
    this.largeCursor.appendTo($("body"));
  }

  /**
   * 创建小光标
   */
  createSmallCursor() {
    this.smallCursor = $("<span></span>");
    this.smallCursor.css({
      width: "10px",
      height: "10px",
      "border-radius": "50%",
      "background-color": cursorColor,
      opacity: 0.1,
    });
    this.smallCursor.appendTo(this.largeCursor);
  }

  /**
   * 创建轨迹光标
   */
  createPathCursor() {
    this.pathContainer = $s("svg");
    this.pathContainer.attr("id", "pathSvg");
    this.pathContainer.css({
      width: window.innerWidth,
      height: window.innerHeight,
      position: "absolute",
      top: 0,
      left: 0,
    });
    this.pathCursor = $s("path");
    this.pathCursor.attr("stroke", cursorColor);
    this.pathCursor.attr("fill", "none");
    this.pathCursor.attr("stroke-width", "2");
    this.pathCursor.appendTo(this.pathContainer);
    this.largeCursor.appendTo($("body"));
  }

  /**
   * cursor id bind to hand id
   */
  bindPointerToHand(handId, handType, index) {
    this.index = index;
    this.id = handId;
    this.handType = handType;
  }

  /**
   *  update cursor position
   */
  updatePosition(hand, frame) {
    // 转换坐标系
    const { palmPos } = leapToScene(hand, frame);
    // 记录光标位置
    this.currX = palmPos[0] + (this.handType === "left" ? +300 : -300);
    this.currY = palmPos[1];
    // 更新光标位置
    this.largeCursor.css({
      left: `${this.currX}px`,
      top: `${this.currY}px`,
    });
  }

  /**
   * remove pointer
   */
  removePointer() {
    this.largeCursor.remove();
  }

  /**
   * 触发光标对应的手势事件
   * @frame 手部帧数据
   */
  triggerHandEvent(hand, gesture) {
    // 识别手势
    this.checkPinching(hand);
    this.checkPointing(hand);
    this.checkDoublePointing(hand);
    this.checkOpen(hand);
    this.checkSwipe(gesture);
  }

  /**
   * 识别手势
   * @hand 单手手部数据
   */
  checkPinching(hand) {
    // 识别手势条件
    const pinchState = distanceBetweenThumbIndex(hand) <= 30; // 25
    const { pinchStrength } = hand; // 捏手的握力

    // 变换光标, 触发事件
    this.changePinchingCursor(pinchStrength);

    if (pinchState && !this.isPinching) {
      this.isPinching = true;
      this.triggerHandPinchStart();
    } else if (pinchState && this.isPinching) {
      this.triggerHandPinching(pinchStrength);
    } else if (!pinchState && this.isPinching) {
      this.triggerHandPinchEnd();
      this.isPinching = false;
    }
  }

  /**
   * 变换捏光标，放大
   * @pinchStrength 捏的力度
   */
  changePinchingCursor(pinchStrength) {
    this.largeCursor.css({
      border: `solid rgba(${cursorColorRGB}, 1) 1px`,
      width: `${pinchStrength * 100 + 10}px`,
      height: `${pinchStrength * 100 + 10}px`,
      "margin-left": `${-(pinchStrength * 100 + 10) / 2}px`,
      "margin-top": `${-(pinchStrength * 100 + 10) / 2}px`,
      "background-color": `rgba(${cursorColorRGB}, .05)`,
    });
  }

  /**
   * 迁移多点触控原理
   */
  handleTouches(eventName) {
    if (eventName === "handpinchstart") {
      const { id, currX, currY, handType, index } = this;
      const pointerInfo = {
        id,
        index,
        currX,
        currY,
        handType,
        order: Object.keys(Pointer.targetTouches).length,
      };
      Pointer.targetTouches[index] = pointerInfo;
      Pointer.changedTouches[index] = pointerInfo;
    }
    if (eventName === "handpinching") {
      const { id, currX, currY, handType, index } = this;
      const pointerInfo = Pointer.targetTouches[index];
      Pointer.targetTouches[index] = {
        ...pointerInfo,
        id,
        currX,
        currY,
        handType,
      };
      Pointer.changedTouches[index] = {
        ...pointerInfo,
        id,
        currX,
        currY,
        handType,
      };
    }
    if (eventName === "handpinchend") {
      const { id, currX, currY, handType, index } = this;
      const pointerInfo = Pointer.changedTouches[index];

      delete Pointer.targetTouches[index];

      Pointer.changedTouches = {};
      Pointer.changedTouches[index] = {
        ...pointerInfo,
        id,
        currX,
        currY,
        handType,
      };
    }
  }

  /**
   * 触发事件
   * @eventName 事件名称
   * todo 支持传出 cursor 对象和传入 element
   */
  triggerEvent(eventName, args) {
    // 创建自定义事件, 可传入第二个参数
    // 事件不会冒泡，不阻止默认行为。默认 {bubbles: false, cancelable: false}
    const event = new Event(eventName, { bubbles: true, cancelable: false });

    // 管理 touches
    this.handleTouches(eventName);

    // 获取光标瞄准的对象元素
    this.largeCursor.hide();
    this.pathContainer?.hide();
    this.wave?.hide();
    const elem = document.elementFromPoint(this.currX, this.currY);
    this.wave?.show();
    this.largeCursor.show();
    this.pathContainer?.show();

    // 事件附着属性
    // event.custom = {
    //   currX: this.currX,
    //   currY: this.currY,
    //   target: elem,
    //   targetTouches: Pointer.targetTouches,
    //   changedTouches: Pointer.changedTouches,
    // };

    if (args) {
      const { pathList, recognize, pinchStrength } = args;
      event.pathList = pathList;
      // 如果分数够高返回返回识别的图形，给到 event 参数
      event.recognize = recognize?.Score > 0.5 ? recognize : {};
      event.cursorRadius = (pinchStrength * 100 + 10) / 2;
    }

    event.pageX = this.currX;
    event.pageY = this.currY;
    event.customTarget = elem;
    event.handType = this.handType;
    event.targetTouches = Pointer.targetTouches;
    event.changedTouches = Pointer.changedTouches;

    // 派发事件
    try {
      elem.dispatchEvent(event);
    } catch (err) {
      console.warn(err);
    }
  }

  /**
   * 触发 HandPinchStart 事件
   */
  triggerHandPinchStart() {
    // 触发事件
    this.triggerEvent("handpinchstart");
  }

  /**
   * 触发 HandPnching 事件
   */
  triggerHandPinching(pinchStrength) {
    this.updateCursorTip("Pinching");

    this.triggerEvent("handpinching", { pinchStrength });
  }

  /**
   * 触发 HandPnchingEnd 事件
   */
  triggerHandPinchEnd() {
    this.cursorTip?.remove();

    this.triggerEvent("handpinchend");
  }

  /**
   * 识别手势
   * @hand 单手手部数据
   */
  checkPointing(hand) {
    // 识别手势条件：
    const pointingState = calPointingGesture(hand);

    if (pointingState && !this.isPointing) {
      this.isPointing = true;
      this.triggerHandPointStart();
    } else if (pointingState && this.isPointing) {
      this.triggerHandPointing();
    } else if (!pointingState && this.isPointing) {
      this.triggerHandPointEnd();
      this.isPointing = false;
    }
  }

  /**
   * 触发 handpointstart 事件
   */
  triggerHandPointStart() {
    $("#pathSvg")?.remove();
    this.pathList = [];
    this.pathContainer?.remove();

    this.triggerEvent("handpointstart");
  }

  /**
   * 触发 handpointing 事件
   */
  triggerHandPointing() {
    this.smallCursor.css({
      "background-color": cursorColor,
      opacity: 1,
    });
    this.largeCursor.css({
      border: `solid rgba(${cursorColorRGB}, 0) 1px`,
      "background-color": `rgba(${cursorColorRGB}, 0.05)`,
    });

    this.pathList.push([this.currX, this.currY]);

    this.updatePathCursor();
    this.updateCursorTip("Pointing");

    this.triggerEvent("handpointing", { pathList: this.pathList });
  }

  /**
   * 触发 handpointend 事件
   */
  triggerHandPointEnd() {
    this.smallCursor.css({
      "background-color": cursorColor,
      opacity: 0.1,
    });
    this.cursorTip?.remove();

    // path recognize
    const path = this.pathList.map((p) => ({ X: p[0], Y: p[1] }));
    const recognize =
      path.length > 0 ? this.recognizer.Recognize(path, true) : null;

    // remove path
    this.pathList = [];
    this.pathContainer?.remove();

    this.triggerEvent("handpointend", { recognize });
  }

  /**
   * 识别 open 相关手势
   */
  checkOpen(hand) {
    const openState = hand.palmNormal[1] > 0.9;
    if (openState && !this.isOpen) {
      this.isOpen = true;
      this.triggerHandOpen();
    } else if (!openState && this.isOpen) {
      this.isOpen = false;
      this.triggerHandOpenEnd();
    }
  }

  /**
   * 触发 handopen 事件
   */
  triggerHandOpen() {
    this.wave?.remove();
    this.wave = $("<span></span>");
    this.wave.css({
      position: "absolute",
      width: "10px",
      height: "10px",
      "margin-left": "-5px",
      "margin-top": "-5px",
      "background-color": cursorColor,
      opacity: ".5",
      "border-radius": "50%",
      left: `${this.currX}px`,
      top: `${this.currY}px`,
    });
    this.wave.animate(
      {
        width: "80px",
        height: "80px",
        "margin-left": "-40px",
        "margin-top": "-40px",
        opacity: "0",
      },
      300,
      "swing"
    );

    this.wave.appendTo($("body"));

    this.updateCursorTip("Open");

    this.triggerEvent("handopen");
  }

  /**
   * 触发 handopenend 事件
   */
  triggerHandOpenEnd() {
    this.cursorTip?.remove();
    this.triggerEvent("handopenend");
  }

  /**
   * 识别 Double pointing 相关手势
   * @hand 单手手部数据
   */
  checkDoublePointing(hand) {
    // 识别手势条件：
    const doublePointingState = calDoublePointingGesture(hand);

    if (doublePointingState && !this.isDoublePointing) {
      this.isDoublePointing = true;
      this.triggerHandDoublePointStart();
    } else if (doublePointingState && this.isDoublePointing) {
      this.triggerHandDoublePointing();
    } else if (!doublePointingState && this.isDoublePointing) {
      this.triggerHandDoublePointEnd();
      this.isDoublePointing = false;
    }
  }

  /**
   * 触发 hand dbpoint start 事件
   */
  triggerHandDoublePointStart() {
    this.pathList = [];
    this.pathContainer?.remove();

    this.triggerEvent("handdbpointstart");
  }

  /**
   * 触发 hand dbpointing 事件
   */
  triggerHandDoublePointing() {
    this.smallCursor.css({
      "background-color": cursorColor,
      opacity: 1,
    });
    this.largeCursor.css({
      border: `solid rgba(${cursorColorRGB}, 0) 1px`,
      "background-color": `rgba(${cursorColorRGB}, 0.05)`,
    });

    this.pathList.push([this.currX, this.currY]);

    this.updatePathCursor();
    this.updateCursorTip("Double Pointing");

    this.triggerEvent("handdbpointing", { pathList: this.pathList });
  }

  updatePathCursor() {
    this.pathContainer?.remove();
    this.createPathCursor();
    this.pathContainer.appendTo($("body"));

    const dPath = getPathD(this.pathList);
    this.pathCursor.attr("d", dPath);
  }

  /**
   * 触发 hand dbpoint end 事件
   */
  triggerHandDoublePointEnd() {
    this.smallCursor.css({
      "background-color": cursorColor,
      opacity: 0.1,
    });

    this.pathList = [];
    this.pathContainer?.remove();

    this.cursorTip?.remove();
    this.triggerEvent("handdbpointend");
  }

  /**
   * 更新光标提示文字
   * @tipText string 提示文字
   */
  updateCursorTip(tipText) {
    this.cursorTip?.remove();
    this.cursorTip = $(`<span>${tipText}</span>`);
    this.cursorTip.css({
      bottom: "-20px",
      position: "absolute",
      width: "200px",
      "text-align": "center",
      color: cursorColor,
    });
    this.cursorTip.appendTo(this.largeCursor);
  }

  /**
   * 识别 swipe 相关手势
   * @gesture object 手势信息
   */
  checkSwipe(gesture) {
    if (!gesture) return;
    const { type, state } = gesture;
    if (type === "swipe") {
      if (state === "start") {
        this.triggerEvent("handswipe");
      }
      if (state === "stop") {
        this.triggerEvent("handswipeend");
      }
    }
  }
}
