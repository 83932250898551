import { RefObject, useState, useEffect } from "react";
import * as d3 from "d3";
import { vec2, vec3, mat3 } from "gl-matrix";
import {
  ELEMENT,
  MODE,
  SCALE_TYPE,
  TERMINAL,
  TERMINAL_CIRCLE_RADIUS,
} from "../components/constant";
import { Point } from "../components/types";
import { getAxisProps } from "../components/canvas";
import { getTransScatterInfo } from "./transform/scatter";
import { getTransParallelInfo } from "./transform/parallel";
import { getTransBarInfo } from "./transform/bar";
import { getTransPieInfo } from "./transform/pie";
import { uuid } from "./main-view";

/** 获取角度 */
export const getAngle = (
  startPos: Point,
  endPos: Point,
  toAngle: boolean = true
): number => {
  // 获取弧度
  const arc = Math.atan2(endPos?.y - startPos?.y, endPos?.x - startPos?.x);
  if (toAngle === false) return arc;
  // 转换成角度
  return Number.isNaN(arc) ? 0 : (arc * 180) / Math.PI;
};

/** 获取长度 */
export const getLength = (startPos: Point, endPos: Point): number => {
  // distance = sqrt(|x|^2 + |y|^2)
  const len = Math.sqrt(
    Math.pow(endPos.y - startPos.y, 2) + Math.pow(endPos.x - startPos.x, 2)
  );
  return Number.isNaN(len) ? 0 : len;
};

/** 根据 scale 类型，获取 ticks */
export const getTicks = (scale: any, axisType: string, len: number) => {
  const tickCount = Math.floor(len / 50);
  if (scale?.type === SCALE_TYPE.LINEAR && axisType === ELEMENT.LINE_AXIS) {
    return scale.ticks([tickCount]).map((tickText: number) => {
      return {
        x: scale(tickText),
        y: 0,
        text: tickText,
      };
    });
  }
  if (scale?.type === SCALE_TYPE.BAND && axisType === ELEMENT.LINE_AXIS) {
    const domain = adjustDomain(tickCount, scale);
    return domain?.map((item: any) => {
      const w = scale.bandwidth();
      return {
        x: scale(item) + w / 2,
        y: 0,
        text: item,
      };
    });
  }
  if (scale?.type === SCALE_TYPE.LINEAR && axisType === ELEMENT.CIRCULAR_AXIS) {
    return scale.ticks([tickCount]).map((tickText: number) => {
      return {
        radian: scale(tickText),
        text: tickText,
      };
    });
  }
  if (scale?.type === SCALE_TYPE.BAND && axisType === ELEMENT.CIRCULAR_AXIS) {
    const domain = adjustDomain(tickCount, scale);
    return domain?.map((item: any) => {
      const w = scale.bandwidth();
      return {
        radian: scale(item) + w / 2,
        text: item,
      };
    });
  }
};

/** 针对 scaleBand，根据 tickCount 给 domain 采样 */
export function adjustDomain(tickCount: number, scale: any) {
  const interval = Math.floor(scale?.domain().length / tickCount);
  let newDomain;
  if (interval === 0) {
    newDomain = scale?.domain();
  } else if (interval === Infinity) {
    newDomain = [];
  } else {
    newDomain = scale
      ?.domain()
      .reduce((total: any[], datum: any, index: number) => {
        if (index % interval === 0) {
          total.push(datum);
        }
        return total;
      }, []);
  }
  return newDomain;
}

/** 如果 scale 是 分类型的，那就增加一个偏移量 scale.bandwidth()/2 */
export const getScaleOffset = (scale: any) => {
  return scale?.type === SCALE_TYPE.BAND ? scale.bandwidth() / 2 : 0;
};

/** 通过数据类型返回对应的比例尺 */
export const getScaleByType = (data: any[], len: number, axisType?: string) => {
  let scale = null;
  let rangeMax = len;
  const valueType = typeof data[0];

  if (axisType === ELEMENT.LINE_AXIS) rangeMax = len;
  if (axisType === ELEMENT.CIRCULAR_AXIS) rangeMax = Math.PI * 2;

  if (valueType === "number") {
    scale = d3
      .scaleLinear()
      .domain([Math.min(...data), Math.max(...data)])
      .range([0, rangeMax])
      .nice();
  }

  if (valueType === "string") {
    scale = d3.scaleBand().domain(data).range([0, rangeMax]).padding(0);
  }

  if (scale) {
    // @ts-ignore
    scale.type = valueType === "number" ? SCALE_TYPE.LINEAR : SCALE_TYPE.BAND;
  }

  return scale;
};

/** 正交吸附
 * desc：当轴本身接近水平的时候，直接变成水平线。垂直同理
 * @param axisInfo(直接更改信息)
 */
export function orthoAdsorption(
  endPos: Point,
  axisInfo: Record<string, any>,
  terminalType: string,
  guideLines: any
) {
  const threshold = 25; // todo
  const { x: x1, y: y1 } = axisInfo.startPos;
  const { x: x2, y: y2 } = axisInfo.endPos;

  if (terminalType === TERMINAL.END) {
    const diffHeight = Math.abs(endPos.y - y1);
    if (diffHeight <= threshold) {
      axisInfo.endPos.y = y1;
      guideLines.horizontal = { x1: 0, y1: y1, x2: window.innerWidth, y2: y1 };
    } else {
      guideLines.horizontal = null;
    }
    const diffWidth = Math.abs(endPos.x - x1);
    if (diffWidth <= threshold) {
      axisInfo.endPos.x = x1;
      guideLines.vertical = { x1: x1, y1: 0, x2: x1, y2: window.innerHeight };
    } else {
      guideLines.vertical = null;
    }
  }

  if (terminalType === TERMINAL.START) {
    const diffHeight = Math.abs(endPos.y - y2);
    if (diffHeight <= threshold) {
      axisInfo.startPos.y = y2;
      guideLines.horizontal = { x1: 0, y1: y2, x2: window.innerWidth, y2: y2 };
    } else {
      guideLines.horizontal = null;
    }
    const diffWidth = Math.abs(endPos.x - x2);
    if (diffWidth <= threshold) {
      axisInfo.startPos.x = x2;
      guideLines.vertical = { x1: x2, y1: 0, x2: x2, y2: window.innerHeight };
    } else {
      guideLines.vertical = null;
    }
  }
}

/** 相互吸附 */
export function terminalAdsorption(
  endPos: Point,
  axisId: string,
  terminalType: string,
  newCanvasData: any[]
) {
  const axisIndex = newCanvasData.findIndex(({ id }) => id === axisId);
  const axisInfo = newCanvasData[axisIndex];
  const otherAxes = newCanvasData.filter(
    (datum) =>
      (datum.type === ELEMENT.LINE_AXIS ||
        datum.type === ELEMENT.CIRCULAR_AXIS) &&
      datum.id !== axisId
  );
  // 原理：当前节点（鼠标位置）与其他节点的位置是否达到最小阈值, 达到则自动吸附上去
  const threshold = 25;
  const { x, y } = endPos;

  const allTerminals = otherAxes.flatMap((axis: any) => [
    [axis.startPos.x, axis.startPos.y],
    [axis.endPos.x, axis.endPos.y],
  ]);

  for (let i = 0; i < allTerminals.length; i++) {
    const terminal = allTerminals[i];
    if (vec2.distance([x, y], terminal as any) < threshold) {
      if (terminalType === TERMINAL.END) {
        axisInfo.endPos.x = terminal[0];
        axisInfo.endPos.y = terminal[1];
      }
      if (terminalType === TERMINAL.START) {
        axisInfo.startPos.x = terminal[0];
        axisInfo.startPos.y = terminal[1];
      }
    }
  }
}

/** 获取轴的包围盒 */
export function getAxisBBox(
  len: number,
  arc: number,
  startPos: Point,
  axisType: string
) {
  let rawPoints = null;
  if (axisType === ELEMENT.CIRCULAR_AXIS) {
    rawPoints = [
      [-len, -len, 1],
      [len, -len, 1],
      [len, len, 1],
      [-len, len, 1],
    ];
  }

  if (axisType === ELEMENT.LINE_AXIS) {
    //  -------------------------------
    // |             bbox              |
    //  -------------------------------
    rawPoints = [
      [0 - TERMINAL_CIRCLE_RADIUS, 0 - TERMINAL_CIRCLE_RADIUS, 1],
      [len + TERMINAL_CIRCLE_RADIUS, 0 - TERMINAL_CIRCLE_RADIUS, 1],
      [len + TERMINAL_CIRCLE_RADIUS, 0 + TERMINAL_CIRCLE_RADIUS, 1],
      [0 - TERMINAL_CIRCLE_RADIUS, 0 + TERMINAL_CIRCLE_RADIUS, 1],
    ];
  }

  const rotateMatrix = mat3.fromRotation(mat3.create(), arc);
  const translateMatrix = mat3.fromTranslation(mat3.create(), [
    startPos.x,
    startPos.y,
  ]);
  const transformPoints = rawPoints?.map((point: any) => {
    const rotate2vec3 = vec3.transformMat3(
      vec3.create(),
      point as any,
      rotateMatrix
    );
    return vec3.transformMat3(
      vec3.create(),
      rotate2vec3 as any,
      translateMatrix
    );
  });
  return transformPoints?.map((point: any) => ({ x: point[0], y: point[1] }));
}

/** custom hook: useHover */
export const useHover = (ref: RefObject<Element>) => {
  const [state, setState] = useState<boolean>(false);

  function handleMouseEnter() {
    setState(true);
  }

  function handleMouseLeave() {
    setState(false);
  }

  useEffect(() => {
    const dom = ref.current;
    ref.current?.addEventListener("mouseenter", handleMouseEnter);
    ref.current?.addEventListener("mouseleave", handleMouseLeave);
    // ref.current?.addEventListener("handpinching", handleMouseEnter);
    // ref.current?.addEventListener("handpinchend", handleMouseLeave);

    return () => {
      dom?.removeEventListener("mouseenter", handleMouseEnter);
      dom?.removeEventListener("mouseleave", handleMouseLeave);
      // dom?.removeEventListener("handpinching", handleMouseEnter);
      // dom?.removeEventListener("handpinchend", handleMouseLeave);
    };
  }, [ref]);

  return state;
};

/** 设置 轴状态 是 被选中的 */
export function setAxisSelected(
  id: string,
  canvasData: any[],
  setCanvasData: any
) {
  const index = canvasData.findIndex((d) => d.id === id);
  canvasData[index].selected = true;
  setCanvasData(canvasData);
}

/** 设置 所有元素的状态 是 没有被选中的 */
export function setAllElemsUnselected(canvasData: any[]) {
  const selectedBoxIndex = canvasData.findIndex(
    (d) => d.type === ELEMENT.SELECTED_BOX
  );
  selectedBoxIndex > -1 && canvasData.splice(selectedBoxIndex, 1);

  return canvasData.map((d) => ({ ...d, selected: false }));
}

/**
 * 判断该模式是否是关于创建图表元素的
 */
export function isPlotForMode(mode: string) {
  if (
    mode === MODE.SCATTER ||
    mode === MODE.PARALLEL ||
    mode === MODE.BAR ||
    mode === MODE.LINE ||
    mode === MODE.PIE
  ) {
    return true;
  }
  return false;
}

/**
 * 模式名称映射到元素名称
 */
export function modeMapToElement(mode: string) {
  switch (mode) {
    case MODE.LINE_AXIS:
      return ELEMENT.LINE_AXIS;
    case MODE.CIRCULAR_AXIS:
      return ELEMENT.CIRCULAR_AXIS;
    case MODE.SCATTER:
      return ELEMENT.SCATTER;
    case MODE.PARALLEL:
      return ELEMENT.PARALLEL;
    case MODE.BAR:
      return ELEMENT.BAR;
    case MODE.LINE:
      return ELEMENT.LINE;
    case MODE.PIE:
      return ELEMENT.PIE;
    default:
      throw new Error("warning: can not find the mode!");
  }
}

/**
 * 创建图表数据信息
 * @param mode 当前模式
 * @param axes 选择两轴的数据
 */
export function createPlotInfo(mode: string, axes: any[]) {
  const [v1, v2] = axes;
  let options;
  switch (mode) {
    case MODE.SCATTER:
      options = getTransScatterInfo(v1, v2);
      break;
    case MODE.PARALLEL:
      options = getTransParallelInfo(v1, v2);
      break;
    case MODE.BAR:
      options = getTransBarInfo(v1, v2);
      break;
    case MODE.LINE:
      options = getTransScatterInfo(v1, v2);
      break;
    case MODE.PIE:
      options = getTransPieInfo(v1, v2);
      break;
    default:
      throw new Error("warning: can not find the mode!");
  }

  const { polyPoints } = options as any;

  return {
    id: uuid(),
    type: ELEMENT.PLOT,
    name: modeMapToElement(mode),
    bbox: polyPoints?.map((p: any) => ({ x: p[0], y: p[1] })),
    axisIds: [v1.id, v2.id],
    ...options,
  };
}

/**
 * 移动两个轴之后，对应的图表也要变换
 * @param plot 需要更新的 plot
 * @param canvasData 最新的 canvasData
 */
export function transformPlot(plot: any, canvasData: any[]) {
  const v1 = canvasData.find(({ id }) => id === plot.axisIds[0]);
  const v2 = canvasData.find(({ id }) => id === plot.axisIds[1]);
  const mapping = plot.mapping;
  let options;
  switch (plot.name) {
    case ELEMENT.SCATTER:
      options = getTransScatterInfo(v1, v2, mapping);
      break;
    case ELEMENT.PARALLEL:
      options = getTransParallelInfo(v1, v2, mapping);
      break;
    case ELEMENT.BAR:
      options = getTransBarInfo(v1, v2, mapping);
      break;
    case ELEMENT.LINE:
      options = getTransScatterInfo(v1, v2);
      break;
    case ELEMENT.PIE:
      options = getTransPieInfo(v1, v2, mapping);
      break;
  }
  return options;
}

/**
 * 更新轴的数据
 * @param axis 需要更新的 axis
 * @param terminalType 拖拽端点的类型
 * @param terminalPos 拖拽端点的位置
 */
export function changeAxisInfo(
  axis: any,
  terminalType: string | null = null,
  terminalPos: Point | null | Point[] = null
) {
  if (terminalType === null) {
    return getAxisProps(
      axis.label,
      axis.data,
      // @ts-ignore
      terminalPos !== null ? terminalPos[0] : axis.startPos,
      // @ts-ignore
      terminalPos !== null ? terminalPos[1] : axis.endPos,
      axis.type
    );
  } else {
    return terminalType === TERMINAL.START
      ? {
          ...axis,
          ...getAxisProps(
            axis.label,
            axis.data,
            terminalPos as Point,
            axis.endPos,
            axis.type
          ),
          startPos: terminalPos,
        }
      : {
          ...axis,
          ...getAxisProps(
            axis.label,
            axis.data,
            axis.startPos,
            terminalPos as Point,
            axis.type
          ),
          endPos: terminalPos,
        };
  }
}

/**
 * 检查两轴是否创建过图表
 */
export function checkAxesHasExsitedPlot(axes: any[], canvasData: any[]) {
  const [a1, a2] = axes;
  const index = canvasData.findIndex(({ axisIds }) => {
    return axisIds?.length > 0
      ? axisIds.includes(a1.id) && axisIds.includes(a2.id)
      : false;
  });
  return index === -1 ? false : true;
}

/** 检测刻度文字是否有重叠 */
export function checkTextCollision(textList: any[]) {
  for (let i = 0; i < textList.length - 1; i++) {
    const text = textList[i];
    const nextText = textList[i + 1];
    // @ts-ignore
    const { x, y, width } = text?.getBoundingClientRect() || {};
    // @ts-ignore
    const {
      x: nx,
      y: ny,
      height: nHeight,
    } = nextText?.getBoundingClientRect() || {};
    if (x + width + 5 > nx && y - 5 < ny + nHeight) {
      return true;
    }
  }
  return false;
}

/** 检查该轴是否用于创建饼图 */
export function checkAxisForPie(canvasData: any[], axisId: string) {
  for (let i = 0; i < canvasData.length; i++) {
    if (
      canvasData[i].name === ELEMENT.PIE &&
      canvasData[i]?.axisIds.includes(axisId)
    ) {
      return true;
    }
  }
  return false;
}

/** 根据情况 transform ticks */
export function getTicksTransform(rotate: number, istextCollided: boolean) {
  // 判断规则:
  // 如果垂直，就旋转
  // 一象限
  if (rotate <= 0 && rotate > -90) {
    return {
      rotation: -rotate + (istextCollided ? 45 : 0),
      translate: [0, istextCollided ? 20 : 30],
      textAnchor: istextCollided ? "start" : "middle",
    };
  }
  // 二象限：一半
  if (rotate <= -90 && rotate >= -135) {
    return {
      rotation: -rotate + (istextCollided ? -45 : 0),
      translate: [-2, istextCollided ? -20 : -20],
      textAnchor: "end",
    };
  }
  // 二象限：剩下一半
  if (rotate < -135 && rotate > -180) {
    return {
      rotation: -rotate + (istextCollided ? -45 : 0),
      translate: [0, istextCollided ? -20 : -20],
      textAnchor: "end",
    };
  }
  // 三象限：
  if (rotate <= 180 && rotate >= 90) {
    return {
      rotation: -rotate + (istextCollided ? 45 : 0),
      translate: [0, istextCollided ? -20 : -20],
      textAnchor: "start",
    };
  }
  // 四象限：
  if (rotate < 90 && rotate > 0) {
    return {
      rotation: -rotate + (istextCollided ? -45 : 0),
      translate: [0, istextCollided ? -20 : -20],
      textAnchor: "start",
    };
  }

  return {
    rotation: -rotate + (istextCollided ? 45 : 0),
    translate: [0, istextCollided ? -20 : -20],
    textAnchor: "start",
  };
}
