import { memo } from "react";
import { Select } from "antd";
const { Option } = Select;

type DropDownMenuProps = {
  setFileName: (s: string) => void;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = memo(
  ({ setFileName }) => {
    // iris: https://www.kaggle.com/datasets/georgesaavedra/covid19-dataset
    // AppleStore: https://www.kaggle.com/datasets/ramamet4/app-store-apple-data-set-10k-apps?select=appleStore_description.csv
    // Youtube: https://www.kaggle.com/datasets/heesoo37/120-years-of-olympic-history-athletes-and-results?select=athlete_events.csv
    const fileList = [
      "cars",
      "stock_msft",
      "seattle_weather_2017",
      "iris",
      "covid_19_data_filter",
      "AppleStore",
      "Youtube",
      "athlete_events",
    ];

    function handleChange(value: string) {
      setFileName(value);
    }

    return (
      <Select
        defaultValue="cars"
        style={{ width: 120, marginLeft: "24px", marginBottom: "16px" }}
        onChange={handleChange}
      >
        {fileList.map((filename) => {
          return <Option value={filename}>{filename}</Option>;
        })}
      </Select>
    );
  }
);
