import { HIGHLIGHT_COLOR } from "../../constant";
import { useRef, useState } from "react";
import { useContextMappingMenu, useSwitchPlot } from "../../../utils/elements";
import { PlotProps } from "../../types";
import "../../canvas/index.css";
import { getPointsForPolygon } from "../../../utils/transform/trans";

/** 平行坐标图的配置属性 */
export type ParallelProps = PlotProps & {
  /** 平行坐标图元素数据 */
  parallelElements: any;
};

export const Parallel: React.FC<ParallelProps> = ({
  id,
  parallelElements,
  polyPoints,
  selected,
  setVisualMappingInfo,
  canvasData,
  setCanvasData,
}) => {
  // console.log("render scatter");
  const [activeState, setActiveState] = useState(false);
  const selectedState = activeState || selected;
  const plot = useRef(null);

  const points = getPointsForPolygon(polyPoints);

  useContextMappingMenu(plot, id, setVisualMappingInfo, canvasData);

  useSwitchPlot(plot, id, canvasData, setCanvasData);

  return (
    <g className="parallel-group">
      {points && (
        <polygon
          ref={plot}
          onMouseEnter={() => {
            setActiveState(true);
          }}
          onMouseLeave={() => {
            setActiveState(false);
          }}
          fill={HIGHLIGHT_COLOR}
          strokeWidth={2}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
          points={points}
        ></polygon>
      )}
      {parallelElements &&
        parallelElements.lines.map((point: any, index: number) => (
          <line
            key={index}
            className="view-element"
            x1={point[0][0]}
            y1={point[0][1]}
            x2={point[1][0]}
            y2={point[1][1]}
            strokeLinecap="round"
            stroke={parallelElements.mappingColors?.[index] || "steelblue"}
            strokeOpacity={parallelElements.mappingOpacitys?.[index] || 0.6}
            strokeWidth={parallelElements.mappingStrokeWidths?.[index] || 1}
          />
        ))}
    </g>
  );
};
