import * as d3 from "d3";
import Collisions from "collisions";
import { vec2 } from "gl-matrix";
import { getAxisProps, HighLightBox } from "../components/canvas";
import { ELEMENT, MODE, TERMINAL } from "../components/constant";
import { Point } from "../components/types";
import { VisualMappingInfo } from "../components/visualMappingMenu";
import {
  checkAxesHasExsitedPlot,
  createPlotInfo,
  isPlotForMode,
  orthoAdsorption,
  setAllElemsUnselected,
  terminalAdsorption,
} from "./axis";
import { uuid } from "./main-view";

// Create the collision system
const system = new Collisions();

export function polygonsCollide(polygon1: Point[], polygon2: Point[]) {
  // Create some walls (represented by Polygons)
  const player = system.createPolygon(
    0,
    0,
    polygon1.map((p) => [p.x, p.y])
  ); //[[], [], [], ...]
  const wall = system.createPolygon(
    0,
    0,
    polygon2.map((p) => [p.x, p.y])
  );

  // Create a Result object for collecting information about the collisions
  const result = system.createResult();

  // Update the collision system
  system.update();

  const res = player.collides(wall, result);

  player.remove();
  wall.remove();

  return res;
}

export function circlesPolygonsCollide(cursor: any, target: any) {
  const { pageX, pageY, cursorRadius } = cursor;
  const player = system.createCircle(pageX, pageY, cursorRadius);
  let wall;

  if (target.type === "polygon") {
    wall = system.createPolygon(
      0,
      0,
      target.bbox.map((p: any) => [p.x, p.y])
    );
  }
  if (target.type === "circle") {
    const { x, y, radius } = target;
    wall = system.createCircle(x, y, radius);
  }

  const result = system.createResult();

  system.update();

  const res = player.collides(wall, result);

  player.remove();
  wall.remove();

  return res;
}

/**
 * 创建轴
 */
export function createAxis(
  startPos: Point,
  canvasData: any[],
  currFieldData: any,
  axisType: string
) {
  const id = uuid();
  canvasData.push({
    id,
    type: axisType,
    ...getAxisProps(
      currFieldData?.name,
      currFieldData?.data,
      startPos,
      startPos,
      axisType
    ),
  });
  return id;
}

/**
 * 高亮框
 */
export function createSelectingBox(
  e: any,
  canvasData: any[],
  elemType: string
) {
  const id = uuid();
  canvasData.push({
    id,
    type: elemType,
    position: { x: e.pageX, y: e.pageY },
  });
  return id;
}

/**
 * 获取高亮框的所有顶点
 */
export function getHighLightBoxPolygon(highLightBox: HighLightBox): Point[] {
  const { position, width, height } = highLightBox;
  const { x, y } = position;
  return [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x: x, y: y + height },
  ];
}

/**
 * 关闭视觉映射菜单
 */
export function closeMappingMenu(
  setVisualMappingInfo: (info: VisualMappingInfo) => void
) {
  setVisualMappingInfo({
    rMenuPos: [-465, 0],
    lMenuPos: [-398, 0],
    plotId: "",
    visualProp: "",
    fieldName: "",
    rMenuShow: false,
    lMenuShow: false,
    rMenuInfo: [],
    dataOverviewMenuShow: false,
    dataOverviewMenuPos: [-398, 0],
  });
}

/**
 * 更新轴
 */
export function updateAxis(
  startPos: Point | null,
  endPos: Point,
  id: string,
  canvasData: any[],
  axisType: string,
  guideLines: any
) {
  const index = canvasData.findIndex((datum) => datum.id === id);
  let axis = canvasData[index];
  canvasData[index] = {
    ...axis,
    ...getAxisProps(
      axis.label,
      axis.data,
      startPos ? startPos : axis.startPos,
      endPos,
      axisType
    ),
    endPos,
  };
  axis = canvasData[index];

  orthoAdsorption(endPos, axis, TERMINAL.END, guideLines);

  if (startPos) {
    terminalAdsorption(startPos, axis.id, TERMINAL.START, canvasData);
  }
  terminalAdsorption(endPos, axis.id, TERMINAL.END, canvasData);

  // startPos 或者 endPos 更新后，需要更新一下对应的长度等
  canvasData[index] = {
    ...axis,
    ...getAxisProps(
      axis.label,
      axis.data,
      axis.startPos,
      axis.endPos,
      axisType
    ),
  };
}

/**
 * 更新高亮框
 */
export function updateSelectingBox(e: any, id: string, canvasData: any[]) {
  const index = canvasData.findIndex((datum) => datum.id === id);
  const box = canvasData[index];
  const position = { ...box.position };
  canvasData[index] = {
    ...box,
    width: Math.max(e.pageX - position.x, 0),
    height: Math.max(e.pageY - position.y, 0),
  };
}

/**
 * 取消引导线
 */
export function removeGuideLines(guideLines: any, setGuideLines: any) {
  guideLines.horizontal = null;
  guideLines.vertical = null;
  setGuideLines({ ...guideLines });
}

/**
 * 观察两个轴是否靠的很近，如果近就直接生成轴
 */
export function checkAxesAndCreateView(id: string, canvasData: any[]) {
  const { mode, closedAxis } = findModeByClosedAxis(id, canvasData);
  const currAxis = canvasData.filter((item) => item.id === id)[0];
  if (closedAxis && mode) {
    const checkState = checkAxesHasExsitedPlot(
      [closedAxis, currAxis],
      canvasData
    );
    if (!checkState) {
      const plotInfo = createPlotInfo(mode, [closedAxis, currAxis]);
      canvasData.push(plotInfo);
    }
  }
  return canvasData;
  // else {
  //   let newCanvasData = null;
  //   // 清除无关的 plot
  //   newCanvasData = canvasData.filter(({ axisIds, type }) => {
  //     if (type !== ELEMENT.PLOT) {
  //       return true;
  //     } else {
  //       //  plot 中保留不存在 id 的
  //       return axisIds.indexOf(id) === -1;
  //     }
  //   });
  //   return newCanvasData;
  // }
}

/**
 * 找到与当前轴最靠近的轴
 * 靠近的判定：
 * 如果轴的类型有圆形轴，则只判断端点距离 < 10，不在意角度
 * 否则：
 * 如果两轴角度 < 45, 并且中心距离 < 200, 为 parallel
 * 如果两轴角度 >= 45, 并且端点距离 < 50, 为 scatter
 */
export function findModeByClosedAxis(id: string, canvasData: any[]) {
  let mode = MODE.SCATTER;
  let closedAxis = null;

  const otherAxes = canvasData.filter(
    (item) =>
      item.id !== id &&
      (item.type === ELEMENT.LINE_AXIS || item.type === ELEMENT.CIRCULAR_AXIS)
  );
  const currAxis = canvasData.filter((item) => item.id === id)[0];
  if (!currAxis) return {};
  const { startPos: currSP, endPos: currEP, type: currType } = currAxis;
  const currVector = [currEP.x - currSP.x, currEP.y - currSP.y];

  for (const axis of otherAxes) {
    const { startPos: SP, endPos: EP, type } = axis;

    const vector = [EP.x - SP.x, EP.y - SP.y];
    const angle =
      (vec2.angle(currVector as any, vector as any) * 180) / Math.PI;

    const centerDist = getDistance(
      getAxisCenterPos(currAxis),
      getAxisCenterPos(axis)
    );
    const startPointDist = getDistance([currSP.x, currSP.y], [SP.x, SP.y]);

    if (currType === ELEMENT.CIRCULAR_AXIS || type === ELEMENT.CIRCULAR_AXIS) {
      if (startPointDist < 10) {
        mode = MODE.BAR;
        closedAxis = axis;
      }
    } else {
      if (angle < 45 && centerDist < 350) {
        mode = MODE.PARALLEL;
        closedAxis = axis;
      }
      if (angle >= 45 && startPointDist < 50) {
        mode = MODE.SCATTER;
        closedAxis = axis;
      }
    }
  }

  return {
    mode,
    closedAxis,
  };
}

/**
 * 寻找轴的中心点
 */
export function getAxisCenterPos(axis: any) {
  const { startPos: s, endPos: e } = axis;
  return [(e.x - s.x) / 2 + s.x, (e.y - s.y) / 2 + s.y];
}

/**
 * 计算两点之间的距离
 */
export function getDistance(p1: number[], p2: number[]) {
  return vec2.distance(p1 as any, p2 as any);
}

/** 删除当前视图，根据“模式”创建新的视图 */
export function switchPlot(
  canvasData: any[],
  plotType: string,
  plotId: string
) {
  if (!plotId) return;
  let newCanvasData = [...canvasData];
  // find plot
  const plot = newCanvasData.filter((d) => d.id === plotId)[0];
  const a1 = newCanvasData.filter((d) => d.id === plot.axisIds[0])[0];
  const a2 = newCanvasData.filter((d) => d.id === plot.axisIds[1])[0];
  const axes = [a1, a2];
  // delete plot
  newCanvasData = newCanvasData.filter((d) => d.id !== plotId);
  // create new plot
  const m = isPlotForMode(plotType) ? plotType : MODE.SCATTER;
  const checkState = checkAxesHasExsitedPlot(axes, newCanvasData);
  if (!checkState && axes.length > 1) {
    const plotInfo = createPlotInfo(m, axes);
    newCanvasData.push(plotInfo);
  }
  return newCanvasData;
}

/** 多选 */
export function multiSelection(polygon1: Point[], canvasData: any[]) {
  // 获取 axes 的 boundingBox
  const allElements = canvasData.filter(
    (item) =>
      item.type === ELEMENT.LINE_AXIS ||
      item.type === ELEMENT.CIRCULAR_AXIS ||
      item.type === ELEMENT.PLOT
  );
  // 取消被选择状态：对所有元素
  let newCanvasData = setAllElemsUnselected(canvasData);

  // 碰撞检测
  for (const elem of allElements) {
    const polygon2 = elem.bbox;
    const isCollided = polygonsCollide(polygon1, polygon2);
    if (isCollided) {
      const index = newCanvasData.findIndex(
        (datum: any) => datum.id === elem.id
      );
      newCanvasData[index] = {
        ...canvasData[index],
        selected: true,
      };
    }
  }

  return newCanvasData;
}

export function createSelectedBox(canvasData: any[]) {
  const selectedBoxIndex = canvasData.findIndex(
    (d) => d.type === ELEMENT.SELECTED_BOX
  );
  selectedBoxIndex > -1 && canvasData.splice(selectedBoxIndex, 1);

  const selectedElems = canvasData
    .filter((d) => d?.selected && d.selected === true)
    .map((d: any) => d.bbox.map((p: Point) => [p.x, p.y]));

  const selectedPoints = selectedElems.reduce((arr, vec) => {
    arr.push(...vec);
    return arr;
  }, []);

  const convexHull = d3.polygonHull(selectedPoints);
  if (convexHull) {
    canvasData.push({
      id: uuid(),
      type: ELEMENT.SELECTED_BOX,
      bbox: convexHull?.map((arr) => ({ x: arr[0], y: arr[1] })),
    });
  }
}

/** 获取对象的第一个属性 */
export function getTouchByOrder(order: number, obj: Record<string, any>) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key].order === order) {
      return obj[key];
    }
  }
}

/**
 * 调整超出窗口边界的菜单位置（适用于任何对象）
 */
export function adjustObjectPos(dom: HTMLElement, originPos: Point) {
  const padding = 20;
  const { height, width } = dom?.getBoundingClientRect();
  let { x, y } = originPos;

  // 超出下边界
  if (y + height > window.innerHeight) {
    y = window.innerHeight - padding - height;
  }
  // 超出上边界
  if (y < 0) {
    y = padding;
  }
  // 超出右边界
  if (x + width > window.innerWidth) {
    x = window.innerWidth - padding - width;
  }
  // 超出左边界
  if (x < 0) {
    x = padding;
  }

  return {
    x,
    y,
  };
}

/** 碰撞检测 */
export function checkCollision(
  targetBBox: Point[],
  allElems: any[],
  callback: (status: boolean, elem: any) => void
) {
  for (const elem of allElems) {
    const elemBBox = elem.bbox;
    const isCollided = polygonsCollide(targetBBox, elemBBox);
    callback(isCollided, elem);
  }
}

/** 检测两个轴是否趋于平行 */
export function checkAngel2CreateParallel(axes: any) {
  const [a1, a2] = axes;
  const { startPos: currSP, endPos: currEP } = a1;
  const currVector = [currEP.x - currSP.x, currEP.y - currSP.y];
  const { startPos: SP, endPos: EP } = a2;
  const vector = [EP.x - SP.x, EP.y - SP.y];

  const angle = (vec2.angle(currVector as any, vector as any) * 180) / Math.PI;
  return angle < 45 ? true : false;
}
