import Leap from "leapjs";
import { Pointer } from "../pointer/pointer";

/**
 * Controller 类，使用 leapG 的入口
 */
export class Controller {
  constructor() {
    /** leap motion controller 类，用于获取手部数据 */
    this.leapController = null;
    /** 光标类 */
    this.pointers = [];
    /** 初始化 leapG */
    this.init();
  }

  /**
   * 初始化 leapG
   */
  init() {
    // 1. 初始化 leapController
    this.leapController = new Leap.Controller({
      enableGestures: true,
    });

    // 2. 侦听手部帧数据
    this.listeningHandFrame();

    // 3. 连接 leapController
    this.leapController.connect();
  }

  /**
   * 侦听手势数据
   */
  listeningHandFrame() {
    // 监听 connect
    this.leapController.on("connect", () => {
      console.log("LeapMotion Device Connect Succeed!");
    });

    // 监听 frame
    this.leapController.on("frame", (frame) => {
      // 创建光标
      this.createPointer(frame);
    });

    // 监听 gesture
    this.leapController.on("gesture", (gesture, frame) => {
      this.createPointer(frame, gesture);
    });
  }

  /**
   * 创建光标
   */
  createPointer(frame, gesture) {
    // 删除多余 pointer
    if (this.pointers.length > frame.hands.length) {
      this.pointers.pop().removePointer();
    }

    // 注意：leapmotion 的 hand.id 很容易变化，每次出画面，id+1
    // 因此 根据 hands 的数量初始化光标的数量
    frame.hands.forEach((hand, index) => {
      const p = this.pointers[index];

      if (!p) {
        // 如果没有，就初始化光标
        this.pointers[index] = new Pointer({
          id: hand.id,
        });
      }

      // 注意 id 一定重新绑定
      this.pointers[index].bindPointerToHand(hand.id, hand.type, index);
      // 更新光标位置
      this.pointers[index].updatePosition(hand, frame);
      // 识别光标对应的手势，并触发事件
      this.pointers[index].triggerHandEvent(hand, gesture);
    });
  }
}
