import React, { memo, useCallback, useEffect, useRef } from "react";
import {
  ELEMENT,
  TERMINAL_CIRCLE_RADIUS,
  TERMINAL,
  MODE,
  HIGHLIGHT_COLOR,
} from "../../constant";
import {
  getTicks,
  orthoAdsorption,
  getAngle,
  terminalAdsorption,
  useHover,
  setAxisSelected,
  checkAxesHasExsitedPlot,
  setAllElemsUnselected,
} from "../../../utils/axis";
import { useMode } from "../../../context/canvas";
import { CircularAxisProps } from "./type";
import {
  isPlotForMode,
  transformPlot,
  createPlotInfo,
  changeAxisInfo,
} from "../../../utils/axis";
import { findModeByClosedAxis } from "../../../utils/canvas";
import { updatePlotInfo } from "../../../utils/plot";

export const CircularAxis: React.FC<CircularAxisProps> = memo((props) => {
  const {
    id,
    label,
    type,
    data,
    scale,
    startPos,
    endPos,
    len,
    bbox,
    selected,
    setCanvasData,
    canvasData,
    axisInfos,
    setGuideLines,
    guideLines,
  } = props;
  const startTerminal = useRef(null);
  const endTerminal = useRef(null);
  const axis = useRef(null);

  // 返回 hoverState
  const startTerminalActive = useHover(startTerminal);
  const endTerminalActive = useHover(endTerminal);
  const axisActive = useHover(axis);

  // scale.ticks(): [5,10,15,20]
  const tickList = getTicks(scale, ELEMENT.CIRCULAR_AXIS, len);
  // tool menu for mode
  const { mode } = useMode();
  // 临时存放 canvasData
  // let newCanvasData = [] as any[];

  let selectedState = selected || axisActive;

  // 用于创建图表的 点击轴的响应函数
  const axisClick = useCallback(
    (info: any) => {
      // 点击做去重处理，防止多次重复点击
      const index = axisInfos.current.findIndex((item) => info.id === item.id);
      if (index === -1) {
        const newCanvasData = [...canvasData];
        setAxisSelected(info.id, newCanvasData, setCanvasData);
        axisInfos.current.push(info); // 全局的被选中两个轴信息
      }
      if (axisInfos.current.length === 2) {
        const m = isPlotForMode(mode) ? mode : MODE.SCATTER;
        const newCanvasData = [...canvasData];
        setAxisSelected(info.id, newCanvasData, setCanvasData);
        const checkState = checkAxesHasExsitedPlot(
          axisInfos.current,
          newCanvasData
        );
        if (!checkState) {
          const plotInfo = createPlotInfo(m, axisInfos.current);
          newCanvasData.push(plotInfo);
          setCanvasData(newCanvasData);
        }
        axisInfos.current = [];
      }
    },
    [axisInfos, canvasData, mode, setCanvasData]
  );

  const handleClickAxis = useCallback(() => {
    axisClick({
      id,
      label,
      type,
      data,
      scale,
      startPos,
      endPos,
      len,
    });
  }, [axisClick, id, label, type, data, scale, startPos, endPos, len]);

  // move: drag one terminal circle of axis
  const circleDragStart = useCallback(
    (e: React.MouseEvent, targetId: string, terminalType: string) => {
      e.preventDefault(); // 阻止默认事件
      // e.stopPropagation(); // 阻止冒泡

      let newCanvasData = [...canvasData];
      const handleDraging = (e: any) => {
        const axisIndex = newCanvasData.findIndex(({ id }) => id === targetId);

        // 1. 单纯更新轴信息
        let axis = newCanvasData[axisIndex];
        let axisInfo = changeAxisInfo(axis, terminalType, {
          x: e.pageX,
          y: e.pageY,
        });
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
        };

        // 2. 正交吸附，轴数据也会更新
        orthoAdsorption(e, newCanvasData[axisIndex], terminalType, guideLines);

        // 3. 端点吸附
        terminalAdsorption(e, targetId, terminalType, newCanvasData);
        axis = newCanvasData[axisIndex];
        axisInfo = changeAxisInfo(axis);
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
        };

        // 4. 轴信息更新后，还要更新对应的 plot 信息
        updatePlotInfo(newCanvasData, targetId);

        // 靠近会识别
        newCanvasData = setAllElemsUnselected(newCanvasData);
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        // const { closedAxis } = findModeByClosedAxis(targetId, newCanvasData);
        // if (closedAxis) {
        //   setAxisSelected(closedAxis.id, newCanvasData, setCanvasData);
        // }

        // 更新画布数据
        setCanvasData((d) => [...newCanvasData]);
        setGuideLines({ ...guideLines });
      };

      const handleDragEnd = () => {
        // const newCanvasData = [...canvasData];
        guideLines.horizontal = null;
        guideLines.vertical = null;

        setGuideLines({ ...guideLines });
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        setCanvasData([...setAllElemsUnselected(newCanvasData)]);

        document.removeEventListener("mousemove", handleDraging);
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("handpinching", handleDraging);
        document.removeEventListener("handpinchend", handleDragEnd);
      };
      document.addEventListener("mousemove", handleDraging);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("handpinching", handleDraging);
      document.addEventListener("handpinchend", handleDragEnd);
    },
    [canvasData, guideLines, setCanvasData, setGuideLines]
  );

  const handleCircleDrag = useCallback(
    (e: any) => {
      const { customTarget } = e;
      const circleType = customTarget.className.baseVal;
      circleDragStart(e, id, circleType);
    },
    [circleDragStart, id]
  );

  // drag hole axis
  const axisDragStart = useCallback(
    (e: React.MouseEvent, targetId: string) => {
      e.preventDefault();
      // e.stopPropagation();
      let newCanvasData = [...canvasData];
      const { pageX: startX, pageY: startY } = e;
      const axisIndex = newCanvasData.findIndex(
        (datum) => datum.id === targetId
      );
      const { startPos, endPos } = newCanvasData[axisIndex];
      const { x: x1, y: y1 } = startPos;
      const { x: x2, y: y2 } = endPos;

      const handleDraging = (e: any) => {
        const offsetX = e.pageX - startX;
        const offsetY = e.pageY - startY;
        const axis = newCanvasData[axisIndex];

        // 靠近会识别
        newCanvasData = setAllElemsUnselected(newCanvasData);
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        // const { closedAxis } = findModeByClosedAxis(targetId, newCanvasData);
        // if (closedAxis) {
        //   setAxisSelected(closedAxis.id, newCanvasData, setCanvasData);
        // }

        const axisInfo = changeAxisInfo(axis, null, [
          { x: x1 + offsetX, y: y1 + offsetY },
          { x: x2 + offsetX, y: y2 + offsetY },
        ]);
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
        };

        const plots = newCanvasData.filter(({ axisIds }) => {
          return axisIds?.length > 0
            ? axisIds[0] === targetId || axisIds[1] === targetId
            : false;
        });

        plots.length > 0 &&
          plots.forEach((plot) => {
            const plotIndex = newCanvasData.findIndex(
              ({ id }) => id === plot.id
            );
            const plotInfo = transformPlot(plot, newCanvasData);
            newCanvasData[plotIndex] = {
              ...newCanvasData[plotIndex],
              ...plotInfo,
            };
          });

        setCanvasData((d) => [...newCanvasData]);
      };
      const handleDragEnd = () => {
        guideLines.horizontal = null;
        guideLines.vertical = null;

        setGuideLines({ ...guideLines });
        // const newData = checkAxesAndCreateView(targetId, newCanvasData);
        // setCanvasData([...setAllElemsUnselected(newData)]);

        document.removeEventListener("mousemove", handleDraging);
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("handpinching", handleDraging);
        document.removeEventListener("handpinchend", handleDragEnd);
      };

      document.addEventListener("mousemove", handleDraging);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("handpinching", handleDraging);
      document.addEventListener("handpinchend", handleDragEnd);
    },
    [canvasData, guideLines, setCanvasData, setGuideLines]
  );
  const handleAxisDrag = useCallback(
    (e) => {
      axisDragStart(e, id);
    },
    [axisDragStart, id]
  );

  useEffect(() => {
    const axisRef = axis.current;
    const startCircleRef = startTerminal.current;
    const endCircleRef = endTerminal.current;
    // @ts-ignore
    axisRef.addEventListener("handdbpointing", handleClickAxis);
    // @ts-ignore
    axisRef.addEventListener("handpinchstart", handleAxisDrag);
    // @ts-ignore
    startCircleRef.addEventListener("handpinchstart", handleCircleDrag);
    // @ts-ignore
    endCircleRef.addEventListener("handpinchstart", handleCircleDrag);
    return () => {
      // @ts-ignore
      axisRef?.removeEventListener("handdbpointing", handleClickAxis);
      // @ts-ignore
      axisRef?.removeEventListener("handpinchstart", handleAxisDrag);
      // @ts-ignore
      startCircleRef.removeEventListener("handpinchstart", handleCircleDrag);
      // @ts-ignore
      endCircleRef.removeEventListener("handpinchstart", handleCircleDrag);
    };
  }, [handleClickAxis, handleAxisDrag, handleCircleDrag, props, id]);

  return (
    <>
      <g
        className="axis"
        transform={`translate(${startPos.x}, ${startPos.y}) rotate(${getAngle(
          startPos,
          endPos
        )})`}
        style={{ cursor: "pointer" }}
      >
        <circle
          cx={0}
          cy={0}
          r={len + 5}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
        ></circle>
        <circle
          cx={0}
          cy={0}
          r={len - 5}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
        ></circle>
        <g>
          <circle
            cx={0}
            cy={0}
            r={len}
            style={{
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
            }}
            ref={axis}
            id={id}
            onClick={() => {
              // 如果模式全部关于 图表类型的 就进来
              if (isPlotForMode(mode)) {
                axisClick({
                  id,
                  label,
                  type,
                  data,
                  scale,
                  startPos,
                  endPos,
                  len,
                });
              }
            }}
            onMouseDown={(e) => {
              if (mode === MODE.SELECT) {
                axisDragStart(e, id);
              }
            }}
          />
          {tickList &&
            tickList.map((tick: any) => {
              return (
                <g
                  transform={`rotate(${
                    (tick.radian * 180) / Math.PI
                  }) translate(${len},0)`}
                >
                  <line
                    x2={-len}
                    style={{
                      stroke: "#999",
                      strokeWidth: 1,
                      strokeDasharray: 4,
                    }}
                  ></line>
                  <line
                    x2={-7}
                    style={{
                      stroke: "black",
                      strokeWidth: 2,
                    }}
                  ></line>
                  <text
                    transform={`rotate(${
                      tick.radian > Math.PI ? 180 : 0
                    }) translate(${tick.radian > Math.PI ? -20 : 0},0)`}
                    y={0}
                    x={10}
                    fontSize={14}
                    textAnchor={`${tick.radian > Math.PI ? "end" : "start"}`}
                    alignmentBaseline={"middle"}
                  >
                    {tick.text}
                  </text>
                </g>
              );
            })}
          <text
            transform={`rotate(90)translate(0,${-len})`}
            x={0}
            y={-70}
            textAnchor={"middle"}
            alignmentBaseline={"middle"}
          >
            {/* {label} */}
          </text>
        </g>
        <circle
          ref={startTerminal}
          onMouseDown={(e) => {
            if (mode === MODE.SELECT) {
              circleDragStart(e, id, TERMINAL.START);
            }
          }}
          cx={0}
          cy={0}
          r={TERMINAL_CIRCLE_RADIUS}
          fill={"#fff"}
          style={{
            stroke: startTerminalActive ? "#6ac1dc" : "black",
            strokeWidth: startTerminalActive ? 3 : 2,
          }}
        ></circle>
        <circle
          ref={endTerminal}
          onMouseDown={(e) => {
            if (mode === MODE.SELECT) {
              circleDragStart(e, id, TERMINAL.END);
            }
          }}
          cx={len}
          cy={0}
          r={TERMINAL_CIRCLE_RADIUS}
          fill={"#fff"}
          style={{
            stroke: endTerminalActive ? "#6ac1dc" : "black",
            strokeWidth: endTerminalActive ? 3 : 2,
          }}
        ></circle>
      </g>
    </>
  );
});
