import * as d3 from "d3";
import { ELEMENT } from "../components/constant";
import { VisualPropInfo } from "../components/visualMappingMenu";

/** 获取元素的视觉属性 */
export function getVisualPropsByPlotType(plotType: string): string[] {
  switch (plotType) {
    case ELEMENT.SCATTER:
      return ["size", "color", "opacity"];
    case ELEMENT.PARALLEL:
      // temp: "firstAxis", "secAxis"
      return ["strokeWidth", "color", "opacity"];
    case ELEMENT.LINE:
      return [];
    case ELEMENT.BAR:
      return ["color"];
    case ELEMENT.PIE:
      return ["color"];
    default:
      return [];
  }
}

/** 获取绘制菜单的数据信息 */
export function getVisualPropsList(
  plotType: string,
  radius: number
): VisualPropInfo[] {
  const visualProps = getVisualPropsByPlotType(plotType);
  return addPaths(visualProps, radius);
}

/** 获取绘制菜单的 path */
export function addPaths(visualProps: string[], radius: number) {
  // 弧线生成器
  const arc_generator = d3
    .arc()
    .innerRadius((radius * 4.4) / 5)
    .outerRadius(radius);

  // label 的弧线生成器，用于计算中心位置
  const arc_label = d3
    .arc()
    .innerRadius(0)
    .outerRadius(radius * 1.9);

  // 角度生成器
  const angle_data = d3.pie().sort(null);

  const tempData = new Array(visualProps.length).fill(1);

  // 生成 path
  const piePath = angle_data(tempData).map((angle) => {
    return arc_generator(angle as any);
  });

  // 计算标题位置
  const propNamePos = angle_data(tempData).map((angle) => {
    return arc_label.centroid(angle as any);
  });

  return piePath.map((d, i) => {
    return {
      propName: visualProps[i],
      path: d as string,
      textPos: propNamePos[i],
      selected: false,
    };
  });
}
