import { HIGHLIGHT_COLOR, SCATTER_CIRCLE_RADIUS } from "../../constant";
import React, { useRef, useState } from "react";
import { useContextMappingMenu, useSwitchPlot } from "../../../utils/elements";
import { PlotProps } from "../../types";
import "../../canvas/index.css";
import { getPointsForPolygon } from "../../../utils/transform/trans";

/** 散点图的配置属性 */
export type ScatterProps = PlotProps & {
  /** 散点图数据 */
  scatterPoints: any;
  /** 元素的坐标系类型 */
  coordType: string; // 'polar' or 'cartesian'
};

export const Scatter: React.FC<ScatterProps> = ({
  id,
  scatterPoints,
  polyPoints,
  coordType,
  selected,
  setVisualMappingInfo,
  canvasData,
  setCanvasData,
}: // mode,
ScatterProps) => {
  // console.log("render scatter");
  const [activeState, setActiveState] = useState(false);
  const selectedState = activeState || selected;
  const plot = useRef(null);
  const { centerPos } = scatterPoints;

  // points = '1, 2, 3, 4, ...';
  const points = getPointsForPolygon(polyPoints);

  useContextMappingMenu(plot, id, setVisualMappingInfo, canvasData);

  useSwitchPlot(plot, id, canvasData, setCanvasData);

  return (
    <g className="scatter-group">
      {points && (
        <polygon
          ref={plot}
          onMouseEnter={() => {
            setActiveState(true);
          }}
          onMouseLeave={() => {
            setActiveState(false);
          }}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeWidth={2}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
          points={points}
        ></polygon>
      )}
      <g
        transform={
          centerPos &&
          `
          translate(${centerPos.x}, 
          ${centerPos.y})`
        }
      >
        {scatterPoints.scatters?.map((point: any, index: number) => (
          <circle
            className="view-element"
            key={index}
            cx={point.x}
            cy={point.y}
            r={scatterPoints?.mappingSizes?.[index] || SCATTER_CIRCLE_RADIUS}
            fill={scatterPoints?.mappingColors?.[index] || "steelblue"}
            fillOpacity={scatterPoints?.mappingOpacitys?.[index] || 1}
            stroke={"#eee"}
          ></circle>
        ))}
      </g>
    </g>
  );
};
