import { memo } from "react";
import { Histogram, Column } from "@ant-design/charts";
import { getAggregateData } from "../../../utils/main-view";

export type MiniPlotProps = {
  width?: number;
  height?: number;
  data: any[];
  events?: boolean;
};

export const MiniPlot: React.FC<MiniPlotProps> = memo(
  ({ width, height, data }) => {
    const datumIsNotNumber = typeof data[0].value !== "number";
    const aggregateMap = getAggregateData(data, "value");
    data = datumIsNotNumber
      ? Array.from(aggregateMap.keys()).map((key) => ({
          type: key,
          count: aggregateMap.get(key),
        }))
      : data;
    const histogramConfig = {
      width,
      height,
      autoFit: false,
      data,
      // binWidth: (max - min) / unit,
      color: "#6ac1dc",
      binField: "value",
      tooltip: {
        showMarkers: false,
        position: "top",
        title: (d: any) => {
          return d.split(",").map((v: number) => Number(v).toFixed(1));
        },
      },
      xAxis: false,
      yAxis: false,
      interactions: [{ type: "element-highlight" }],
    };

    const columnConfig = {
      width,
      height,
      autoFit: false,
      data,
      xField: "type",
      yField: "count",
      color: "#6ac1dc",
      xAxis: false,
      yAxis: false,
      tooltip: {
        showMarkers: false,
        position: "top",
        title: (d: string) => d,
      },
      interactions: [{ type: "element-highlight" }],
    };

    return datumIsNotNumber ? (
      //@ts-ignore
      <Column {...columnConfig} />
    ) : (
      //@ts-ignore
      <Histogram {...histogramConfig} />
    );
  }
);
