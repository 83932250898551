import { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import { PageHeader } from "antd";
import {
  SchemaMenu,
  SchemaMenuProps,
  FieldInfo,
} from "../../components/schemaMenu";
import { DropDownMenu } from "../../components/dropDownMenu";
import { Canvas } from "../../components/canvas";
import { ToolMenu } from "../../components/toolMenu";
import { isMatchStringKey } from "../../utils/main-view";
import { ModeProvider } from "../../context/canvas";
import {
  VisualMappingInfo,
  VisualMappingMenu,
} from "../../components/visualMappingMenu";
import { FloatDataMenu } from "../../components/floatDataMenu";
import * as leapG from "../../lib/leapG/index.js";
import "./index.css";
import { getAggregateData } from "../../utils";

/**
 * 获取原始数据
 */
async function fetchCsv(fileName: string) {
  const response = await fetch("/data/" + `${fileName}.csv`); // public file
  const reader = response.body!.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder("utf-8");
  const csv = await decoder.decode(result.value);
  const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
  const originalData = results.data as Record<string, any>[]; // array of objects
  const rows = Papa.parse(csv);
  // 预处理数据
  originalData.forEach((row: Record<string, any>) => {
    // 自动识别 数据类型 ‘12.3’ => number, 'abc' => string
    // row => {'MPG': 123, 'Cylinder': '7-8'}
    Object.keys(row).forEach((key) => {
      if (row.hasOwnProperty(key)) {
        // 判断是否是数字或小数
        const numberReg = /.*[0-9]{1,}.*/;
        if (isMatchStringKey(key)) {
          row[key] = String(row[key]);
        } else if (row[key].match(numberReg)) {
          if (row[key].includes(",")) {
            const num = row[key].replaceAll(",", "");
            row[key] = Number(num);
          } else {
            row[key] = Number(row[key]);
          }
        }
      }
    });
  });

  return { originalData, rows };
}

/**
 * 处理成 schema panel 所需的数据格式
 */
function getAllFieldsData(
  fileName: string,
  originalData: Record<string, any>[],
  rows: any
) {
  const fieldsData = (rows.data[0] as string[]).map((field) => {
    const data = originalData.map((item) => ({
      value: item[field],
    }));
    return {
      fileName,
      name: field,
      type: typeof originalData[0][field],
      data: data.filter((datum) => {
        return (
          typeof datum.value === "number" ||
          (typeof datum.value === "string" && datum.value !== "")
        );
      }), // 过滤掉有空数值的 row
      selected: false,
      active: false,
    };
  });

  addStaticData(fieldsData);

  return fieldsData;
}

function addStaticData(fieldsData: any) {
  fieldsData.map((item: any) => {
    const aggregateMap = getAggregateData(item.data, "value");

    // 每个 key 的名称和 count
    const data = Array.from(aggregateMap.keys()).map((key) => ({
      value: key,
    }));

    fieldsData.push({
      fileName: item.fileName,
      name: item.name + "_unique",
      type: typeof data[0].value,
      data: data.filter((datum: any) => {
        return (
          typeof datum.value === "number" ||
          (typeof datum.value === "string" && datum.value !== "")
        );
      }), // 过滤掉有空数值的 row
      selected: false,
      active: false,
    });

    const count = Array.from(aggregateMap.keys()).map((key) => ({
      value: aggregateMap.get(key),
    }));

    fieldsData.push({
      fileName: item.fileName,
      name: item.name + "_count",
      type: typeof count[0].value,
      data: count.filter((datum: any) => {
        return (
          typeof datum.value === "number" ||
          (typeof datum.value === "string" && datum.value !== "")
        );
      }), // 过滤掉有空数值的 row
      selected: false,
      active: false,
    });
  });
}

/**
 * 主视图页面
 */
export const MainView: React.FC = () => {
  // 文件名称
  const [fileName, setFileName] = useState<string>("Cars");
  // 维度数据
  const [allFieldsData, setAllFieldsData] = useState<
    SchemaMenuProps["allFieldsData"]
  >([]);
  // 当前维度数据
  const [currFieldData, setCurrFieldData] = useState<FieldInfo>();
  // 画布数据
  const [canvasData, setCanvasData] = useState<Record<string, any>[]>([]);
  // 拖拽时克隆的维度列表项目
  const [cloneItemInfo, setCloneItemInfo] = useState<any>();
  // 映射相关数据
  const [visualMappingInfo, setVisualMappingInfo] = useState<VisualMappingInfo>(
    {
      rMenuPos: [-465, 0],
      lMenuPos: [-398, 0],
      plotId: '""',
      rMenuShow: false,
      lMenuShow: false,
      visualProp: "",
      fieldName: "",
      rMenuInfo: [],
      dataOverviewMenuShow: false,
      dataOverviewMenuPos: [0, 120],
    }
  );
  const globalStatus = useRef({
    isSelectSchemaItem: false,
    isDragingItem: false,
    leapG: {},
    logData: [],
  });

  useEffect(() => {
    fetchCsv(fileName).then(({ originalData, rows }) => {
      const fields = getAllFieldsData(fileName, originalData, rows);
      fields[0].selected = true;

      setAllFieldsData(fields);
      setCurrFieldData(fields[0]);
    });

    globalStatus.current.leapG = new leapG.Controller();
  }, [fileName]);

  return (
    <ModeProvider>
      <div>
        <Canvas
          canvasData={canvasData}
          globalStatus={globalStatus}
          currFieldData={currFieldData as FieldInfo}
          setCanvasData={setCanvasData}
          setCloneItemInfo={setCloneItemInfo}
          setVisualMappingInfo={setVisualMappingInfo}
        />
        <VisualMappingMenu
          canvasData={canvasData}
          visualMappingInfo={visualMappingInfo}
          setVisualMappingInfo={setVisualMappingInfo}
        />
        <PageHeader
          className="site-page-header"
          title="Building Vis By Hands"
          subTitle="Build Visualization with mid-air interaction"
        />
        <DropDownMenu setFileName={setFileName} />
        <SchemaMenu
          globalStatus={globalStatus}
          canvasData={canvasData}
          setCanvasData={setCanvasData}
          currFieldData={currFieldData as FieldInfo}
          allFieldsData={allFieldsData}
          setAllFieldsData={setAllFieldsData}
          setCurrFieldData={setCurrFieldData}
          visualMappingInfo={visualMappingInfo}
          cloneItemInfo={cloneItemInfo}
          setCloneItemInfo={setCloneItemInfo}
          setVisualMappingInfo={setVisualMappingInfo}
        />
        <ToolMenu
          canvasData={canvasData}
          currFieldData={currFieldData as FieldInfo}
          setCanvasData={setCanvasData}
          globalStatus={globalStatus}
        />
        <FloatDataMenu
          visualMappingInfo={visualMappingInfo}
          setVisualMappingInfo={setVisualMappingInfo}
          canvasData={canvasData}
          setCanvasData={setCanvasData}
          setCurrFieldData={setCurrFieldData}
          allFieldsData={allFieldsData}
          setAllFieldsData={setAllFieldsData}
        />
      </div>
    </ModeProvider>
  );
};
