import { transformPlot } from "./axis";

/**
 * 更新 plot 信息（当轴更新时
 */
export function updatePlotInfo(canvasData: any[], axisId: string) {
  const plots = canvasData.filter(({ axisIds }) => {
    return axisIds?.length > 0 ? axisIds.includes(axisId) : false;
  });
  plots.length > 0 &&
    plots.forEach((plot) => {
      const plotIndex = canvasData.findIndex(({ id }) => id === plot.id);
      const plotInfo = transformPlot(plot, canvasData);
      canvasData[plotIndex] = {
        ...canvasData[plotIndex],
        ...plotInfo,
      };
    });
}
