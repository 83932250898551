import { memo, useCallback, useEffect, useRef } from "react";
import { FieldInfo } from "../schemaMenu";
import "./index.css";
import { VisualMappingInfo } from "../visualMappingMenu";
import { updatePlotInfo } from "../../utils/plot";
import { SCHEMA_ITEM_WIDTH } from "../constant";
import { closeMappingMenu } from "../../utils/canvas";

export type FloatDataMenuProps = {
  /** 设置画布数据 */
  setCanvasData: (d: Record<string, any>[]) => void;
  /** 画布数据 */
  canvasData: Record<string, any>[];
  /** 设置当前 field 字段的数据 */
  setCurrFieldData: (v: FieldInfo) => void;
  /** 数据文件列表 */
  allFieldsData: FieldInfo[];
  /** 设置数据文件列表 */
  setAllFieldsData: any;
  /** 映射信息 */
  visualMappingInfo: VisualMappingInfo;
  /** 设置映射信息 */
  setVisualMappingInfo: (
    info: VisualMappingInfo | ((info: VisualMappingInfo) => VisualMappingInfo)
  ) => void;
};

/**
 * 上下文数据菜单
 */
export const FloatDataMenu: React.FC<FloatDataMenuProps> = memo(
  ({
    canvasData,
    allFieldsData,
    setAllFieldsData,
    setCanvasData,
    visualMappingInfo,
    setVisualMappingInfo,
  }) => {
    // const { mode } = useMode();
    const { visualProp, lMenuShow, lMenuPos, plotId } = visualMappingInfo;
    const dataItems = useRef([]);

    const handleClick = useCallback(
      (e: any) => {
        const index = Number(e.target.getAttribute("data-index"));
        const fieldName = e.target.getAttribute("data-name");
        const visualProp = e.target.getAttribute("visual-prop-name");

        const infos = allFieldsData.map((item) => ({
          ...item,
          selected: false,
        }));
        infos[index].selected = true;
        setAllFieldsData(infos);

        setVisualMappingInfo((prev) => ({ ...prev, fieldName }));
        // plotId
        let newCanvasData = [...canvasData];
        const plot = newCanvasData.filter((d) => d.id === plotId)[0];
        const currFieldData = allFieldsData.filter(
          (d) => d.name === fieldName
        )[0];
        newCanvasData.forEach((d, i) => {
          if (d.id === plotId) {
            newCanvasData[i] = {
              ...d,
              mapping: {
                ...d.mapping,
                [visualProp]: currFieldData,
              },
            };
          }
        });
        // 找到对应的轴 id：
        const axisId = plot?.axisIds[0];
        if (!axisId) return;
        updatePlotInfo(newCanvasData, axisId);
        setCanvasData([...newCanvasData]);
      },
      [
        setCanvasData,
        canvasData,
        allFieldsData,
        setAllFieldsData,
        plotId,
        setVisualMappingInfo,
      ]
    );

    const handleClickEnd = useCallback(
      (e: any) => {
        closeMappingMenu(setVisualMappingInfo);
      },
      [setVisualMappingInfo]
    );

    useEffect(() => {
      const doms = dataItems.current;
      for (const dom of doms) {
        //@ts-ignore
        dom?.addEventListener("handpointing", handleClick);
        //@ts-ignore
        dom?.addEventListener("handpointend", handleClickEnd);
      }
      return () => {
        for (const dom of doms) {
          //@ts-ignore
          dom?.removeEventListener("handpointing", handleClick);
          //@ts-ignore
          dom?.removeEventListener("handpointend", handleClickEnd);
        }
      };
    }, [handleClick, visualMappingInfo]);

    return (
      <>
        {lMenuShow && (
          <ul
            id="float-data-menu"
            style={{
              position: "absolute",
              visibility: lMenuShow ? "visible" : "hidden",
              left: lMenuPos[0] + "px",
              top: lMenuPos[1] + "px",
              padding: 0,
              margin: 0,
              display: "flex",
              flexWrap: "wrap",
              width: SCHEMA_ITEM_WIDTH * 3 + 10 + "px",
              flexDirection: "inherit",
            }}
          >
            {allFieldsData.map(({ name, type, selected }, index) => {
              return (
                <li
                  key={index}
                  ref={(dom: any) => {
                    //@ts-ignore
                    dataItems.current[index] = dom;
                  }}
                  style={{
                    backgroundColor: selected ? "#e9f6fe" : "#fff",
                    width: 287,
                  }}
                  className="data-item"
                  visual-prop-name={visualProp}
                  data-name={name}
                  data-index={index}
                  onMouseDown={(e) => {
                    handleClick(e);
                  }}
                >
                  <h4
                    visual-prop-name={visualProp}
                    data-name={name}
                    data-index={index}
                    onMouseDown={(e) => {
                      handleClick(e);
                    }}
                  >
                    {name}
                    <br></br>({type})
                  </h4>
                </li>
              );
            })}
          </ul>
        )}
      </>
    );
  }
);
