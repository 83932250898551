import { RefObject, useCallback, useEffect } from "react";
import { VisualMappingInfo } from "../components/visualMappingMenu";
import { switchPlot } from "./canvas";
import { getVisualPropsList } from "./mapping-menu";

export function useContextMappingMenu(
  ref: RefObject<Element>,
  plotId: string,
  setVisualMappingInfo: (info: any) => void,
  canvasData: any[]
) {
  let plot = canvasData?.find(({ id }) => id === plotId);
  const plotType = plot ? plot.name : "";

  const handleContextMenu = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setVisualMappingInfo((prev: VisualMappingInfo) => ({
        ...prev,
        rMenuPos: [e.pageX, e.pageY],
        plotId,
        radius: 200,
        rMenuShow: true,
        dataOverviewMenuShow: false,
        rMenuInfo: getVisualPropsList(plotType, 200),
      }));
    },
    [setVisualMappingInfo, plotId, plotType]
  );

  useEffect(() => {
    const dom = ref.current;
    dom?.addEventListener("contextmenu", handleContextMenu);
    dom?.addEventListener("handopen", handleContextMenu);
    return () => {
      dom?.removeEventListener("contextmenu", handleContextMenu);
      dom?.removeEventListener("handopen", handleContextMenu);
    };
  }, [ref, handleContextMenu]);
}

export function useSwitchPlot(
  ref: RefObject<Element>,
  plotId: string,
  canvasData: any[],
  setCanvasData: any
) {
  /** 切换视图 */
  const handleSwitchPlot = useCallback(
    (e) => {
      e.stopPropagation();

      const { recognize } = e;
      const newData = switchPlot(canvasData, recognize.Name, plotId);
      setCanvasData(newData);
    },
    [canvasData, plotId, setCanvasData]
  );

  useEffect(() => {
    const plotRef = ref.current;

    // @ts-ignore
    plotRef?.addEventListener("handpointend", handleSwitchPlot);

    return () => {
      // @ts-ignore
      plotRef?.removeEventListener("handpointend", handleSwitchPlot);
    };
  }, [handleSwitchPlot, ref]);
}
