import { useEffect, useState } from "react";

// 判断值是否为 false、undefined、null 等，排除 0
const isFalsy = (value: unknown) => {
  return value === 0 ? false : !value;
};

// 如果 属性值 是 undefined 、null、 false 等等，就删除该 key
export const cleanObject = (object: object) => {
  const result = { ...object };
  Object.keys(object).forEach((key: string) => {
    //@ts-ignore
    const value = object[key];
    if (isFalsy(value)) {
      //@ts-ignore
      delete result[key];
    }
  });
  return result;
};

/** 在页面刚加载的时候，执行某函数 */
export const useMount = (callback: () => void) => {
  useEffect(() => {
    callback();
  }, []);
};

/** 在 value 值修改之后，触发 debounce */
export const useDebounce = <V>(value: V, delay?: number) => {
  const [debouncedValue, setDebounceValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebounceValue(value), delay);
    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
};

/**
 * 相当于 groupBy，获取 字段值 出现的频率
 */
export function getAggregateData(data: any[], field: string) {
  const list = new Map();
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    let count = list.get(row[field]);
    if (count) {
      list.set(row[field], ++count);
    } else {
      list.set(row[field], 1);
    }
  }
  return list;
}
