import { AxisInfo } from "../../components/types";
import { vec2 } from "gl-matrix";
import { getTransformMatrix, getSecTransVec3, Contour } from "./trans";
import * as d3 from "d3";
import { getMapping } from "./scatter";

/**
 * 获取饼图（高亮框）数据
 * @param v1 轴信息（vector）
 * @param v2 轴信息（vector）
 */
export function getTransPieInfo(
  v1: AxisInfo | any,
  v2: AxisInfo | any,
  mapping?: Record<string, any>
) {
  // 准备饼图位置数组
  const pieRadius = v1.len < v2.len ? v1.len / 2 : v2.len / 2;

  // 准备高亮散点框的坐标数组
  const polyPadding = 0;
  const polyOrthoPoints = [
    [0 - polyPadding, 0 - polyPadding, 1],
    [v1.len + polyPadding, 0 - polyPadding, 1],
    [v1.len + polyPadding, v2.len + polyPadding, 1],
    [0 - polyPadding, v2.len + polyPadding, 1],
  ];

  // #7: fix bug for parallel axes
  const realV1 = [v1.endPos.x - v1.startPos.x, v1.endPos.y - v1.startPos.y];
  const realV2 = [v2.endPos.x - v2.startPos.x, v2.endPos.y - v2.startPos.y];
  const v = vec2.cross([0, 0, 0], realV1 as any, realV2 as any);
  if (v[2] === 0) return { polyPoints: null, scatterPoints: null };

  const { shear_matrix, firstTranslation, secTranslation } = getTransformMatrix(
    v1,
    v2
  );

  const polyPoints = [];
  for (const item of polyOrthoPoints) {
    const secTransVec3 = getSecTransVec3(
      item,
      shear_matrix,
      firstTranslation,
      secTranslation
    );
    polyPoints.push(secTransVec3);
  }

  const tempPolyPoints = polyPoints.map((p) => ({ x: p[0], y: p[1] }));
  // @ts-ignore
  const piePos = new Contour(tempPolyPoints).centroid();

  // 弧度生成器
  const arc_generator = d3.arc().innerRadius(0).outerRadius(pieRadius);

  // label 位置
  const arc_label = d3
    .arc()
    .innerRadius(0)
    .outerRadius(pieRadius * 2.5);

  // 函数：生成角度
  const angle_data = d3.pie().sort(null);

  const piePath = angle_data(v1.data.map((d: any) => d.value)).map((angle) => {
    return arc_generator(angle as any);
  });

  const pieTextPos = angle_data(v1.data.map((d: any) => d.value)).map(
    (angle) => {
      return arc_label.centroid(angle as any);
    }
  );

  const pieText = v2.data.map((d: any) => d.value);

  return {
    polyPoints,
    bbox: polyPoints.map((p) => ({ x: p[0], y: p[1] })),
    pieElements: {
      piePos,
      piePath,
      pieTextPos,
      pieText,
      ...getMapping(mapping),
    },
  };
}
