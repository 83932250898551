import { HIGHLIGHT_COLOR } from "../../constant";
import { useRef, useState } from "react";
import { useContextMappingMenu, useSwitchPlot } from "../../../utils/elements";
import { PlotProps } from "../../types";
import "../../canvas/index.css";
import { getPointsForPolygon } from "../../../utils/transform/trans";

/** 饼图的配置属性 */
export type PieProps = PlotProps & {
  /** 饼图图元素数据 */
  pieElements: any;
};

export const Pie: React.FC<PieProps> = ({
  id,
  pieElements,
  polyPoints,
  selected,
  setVisualMappingInfo,
  canvasData,
  setCanvasData,
}) => {
  // console.log("render line");
  const [activeState, setActiveState] = useState(false);
  const selectedState = activeState || selected;
  const plot = useRef(null);

  const points = getPointsForPolygon(polyPoints);

  const ref = useRef(null);
  useContextMappingMenu(ref, id, setVisualMappingInfo, canvasData);

  useSwitchPlot(plot, id, canvasData, setCanvasData);

  return (
    <g className="bar-group" ref={ref}>
      {points && (
        <polygon
          ref={plot}
          onMouseEnter={() => {
            setActiveState(true);
          }}
          onMouseLeave={() => {
            setActiveState(false);
          }}
          fill={HIGHLIGHT_COLOR}
          fillOpacity={0}
          stroke={HIGHLIGHT_COLOR}
          strokeWidth={2}
          strokeOpacity={selectedState ? 0.7 : 0}
          strokeDasharray={3}
          points={points}
        ></polygon>
      )}
      {pieElements && (
        <g
          transform={`translate(${pieElements.piePos.x}, ${pieElements.piePos.y})`}
        >
          {pieElements.piePath.map((d: any, i: number) => {
            const [x, y] = pieElements.pieTextPos[i];
            return (
              <g key={i}>
                <path
                  className="view-element"
                  d={d}
                  fill={pieElements.mappingColors?.[i] || "steelblue"}
                  stroke={"#eee"}
                  strokeWidth={1}
                />
                <text x={x} y={y} textAnchor={"middle"}>
                  {pieElements.pieText[i]}
                </text>
              </g>
            );
          })}
        </g>
      )}
    </g>
  );
};
