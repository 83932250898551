import { useState, useLayoutEffect } from "react";

/** hook 监听窗口变化 */
export function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  // 窗口一旦变化，就重新设置窗口值大小
  const resize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return [width, height];
}

/** 用来暂时设置 字段 对应的 值类型 */
export const isMatchStringKey = (key: string) => {
  const keys = ["Origin", "Year", "youtuber", "Cylinders", "DATE"];
  return keys.includes(key);
};

/**
 * 相当于 groupBy，获取 字段值 出现的频率
 */
export function getAggregateData(data: any[], field: string) {
  const list = new Map();
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    let count = list.get(row[field]);
    if (count) {
      list.set(row[field], ++count);
    } else {
      list.set(row[field], 1);
    }
  }
  return list;
}

/**
 * 给元素生成唯一的id
 * @return {返回值类型} 唯一id
 */
export function uuid(): string {
  let s = [] as any;
  const hexDigits = "0123456789abcdef";
  s[0] = "a";
  for (let i = 1; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  const uuid = s.join("");
  return uuid;
}
