import React, { memo, useCallback, useEffect, useRef } from "react";
import {
  ELEMENT,
  TERMINAL_CIRCLE_RADIUS,
  TERMINAL,
  MODE,
  HIGHLIGHT_COLOR,
} from "../../constant";
import {
  getTicks,
  orthoAdsorption,
  getAngle,
  terminalAdsorption,
  useHover,
  setAxisSelected,
  isPlotForMode,
  createPlotInfo,
  changeAxisInfo,
  checkAxesHasExsitedPlot,
  setAllElemsUnselected,
  checkTextCollision,
  checkAxisForPie,
  getTicksTransform,
} from "../../../utils/axis";
import { useMode } from "../../../context/canvas";
import { Point } from "../../types";
import { AxisProps } from "./type";
import { updatePlotInfo } from "../../../utils/plot";
import { updateAxis } from "../../../utils/canvas";

export const Axis: React.FC<AxisProps> = memo((props) => {
  const {
    id,
    label,
    type,
    data,
    scale,
    startPos,
    endPos,
    len,
    bbox,
    selected,
    setCanvasData,
    canvasData,
    axisInfos,
    setGuideLines,
    guideLines,
    globalStatus,
  } = props;
  const startTerminal = useRef(null);
  const endTerminal = useRef(null);
  const axis = useRef(null);
  const textList = useRef([]);
  const textLabel = useRef(null);

  // 返回 hoverState
  const startTerminalActive = useHover(startTerminal);
  const endTerminalActive = useHover(endTerminal);
  const axisActive = useHover(axis);

  // scale.ticks(): [5,10,15,20]
  const tickList = getTicks(scale, ELEMENT.LINE_AXIS, len);

  // tool menu for mode
  const { mode } = useMode();

  let selectedState = selected || axisActive;

  // 用于创建图表的 点击轴的响应函数
  const axisClick = useCallback(
    (info: any) => {
      // 点击做去重处理，防止多次重复点击
      const index = axisInfos.current.findIndex((item) => info.id === item.id);
      if (index === -1) {
        const newCanvasData = [...canvasData];
        setAxisSelected(info.id, newCanvasData, setCanvasData);
        axisInfos.current.push(info); // 全局的被选中两个轴信息
      }
    },
    [axisInfos, canvasData, mode, setCanvasData]
  );

  const handleClickAxis = useCallback(() => {
    axisClick({
      id,
      label,
      type,
      data,
      scale,
      startPos,
      endPos,
      len,
    });
  }, [axisClick, id, label, type, data, scale, startPos, endPos, len]);

  // move: drag one terminal circle of axis
  const circleDragStart = useCallback(
    (e: React.MouseEvent, targetId: string, terminalType: string) => {
      e.preventDefault(); // 阻止默认事件
      e.stopPropagation(); // 阻止冒泡

      globalStatus.current.isDragingItem = true;

      let newCanvasData = [...canvasData];

      const handleDraging = (e: any) => {
        const axisIndex = newCanvasData.findIndex(({ id }) => id === targetId);

        // 1. 单纯更新轴信息
        let axis = newCanvasData[axisIndex];
        let axisInfo = changeAxisInfo(axis, terminalType, {
          x: e.pageX,
          y: e.pageY,
        });
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
        };

        // 2. 正交吸附，轴数据也会更新
        orthoAdsorption(
          { x: e.pageX, y: e.pageY },
          newCanvasData[axisIndex],
          terminalType,
          guideLines
        );

        // 3. 端点吸附
        terminalAdsorption(
          { x: e.pageX, y: e.pageY },
          targetId,
          terminalType,
          newCanvasData
        );

        // 靠近会识别
        newCanvasData = setAllElemsUnselected(newCanvasData);
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        // const { closedAxis } = findModeByClosedAxis(targetId, newCanvasData);
        // if (closedAxis) {
        //   setAxisSelected(closedAxis.id, newCanvasData, setCanvasData);
        // }

        axis = newCanvasData[axisIndex];
        axisInfo = changeAxisInfo(axis);
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
          selected: true,
        };

        // 4. 轴信息更新后，还要更新对应的 plot 信息
        updatePlotInfo(newCanvasData, targetId);

        // 更新画布数据
        setCanvasData((d) => [...newCanvasData]);
        setGuideLines({ ...guideLines });
      };

      const handleDragEnd = () => {
        // const newCanvasData = [...canvasData];
        guideLines.horizontal = null;
        guideLines.vertical = null;

        globalStatus.current.isDragingItem = false;

        setGuideLines({ ...guideLines });
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        setCanvasData([...setAllElemsUnselected(newCanvasData)]);

        document.removeEventListener("mousemove", handleDraging);
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("handpinching", handleDraging);
        document.removeEventListener("handpinchend", handleDragEnd);
      };
      document.addEventListener("mousemove", handleDraging);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("handpinching", handleDraging);
      document.addEventListener("handpinchend", handleDragEnd);
    },
    [canvasData, guideLines, setCanvasData, setGuideLines, globalStatus]
  );

  const handleCircleDrag = useCallback(
    (e: any) => {
      const { customTarget } = e;
      const circleType = customTarget.className.baseVal;
      circleDragStart(e, id, circleType);
    },
    [circleDragStart, id]
  );

  // drag hole axis
  const axisDragStart = useCallback(
    (e: React.MouseEvent, targetId: string) => {
      e.preventDefault();
      e.stopPropagation();

      globalStatus.current.isDragingItem = true;

      let newCanvasData = [...canvasData];
      const { pageX: startX, pageY: startY } = e;

      const axisIndex = newCanvasData.findIndex(
        (datum) => datum.id === targetId
      );

      const { startPos, endPos } = newCanvasData[axisIndex];
      const { x: x1, y: y1 } = startPos;
      const { x: x2, y: y2 } = endPos;

      const handleDraging = (e: any) => {
        const offsetX = e.pageX - startX;
        const offsetY = e.pageY - startY;
        const axis = newCanvasData[axisIndex];

        // 靠近会识别
        newCanvasData = setAllElemsUnselected(newCanvasData);
        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        // const { closedAxis } = findModeByClosedAxis(targetId, newCanvasData);
        // if (closedAxis) {
        //   setAxisSelected(closedAxis.id, newCanvasData, setCanvasData);
        // }

        // 轴信息更新后，还要更新对应的 plot 信息
        updatePlotInfo(newCanvasData, targetId);

        const axisInfo = changeAxisInfo(axis, null, [
          { x: x1 + offsetX, y: y1 + offsetY },
          { x: x2 + offsetX, y: y2 + offsetY },
        ]);
        newCanvasData[axisIndex] = {
          ...axis,
          ...axisInfo,
          selected: true,
        };

        // 端点吸附
        updateAxis(
          newCanvasData[axisIndex].startPos,
          newCanvasData[axisIndex].endPos,
          targetId,
          newCanvasData,
          ELEMENT.LINE_AXIS,
          guideLines
        );

        setCanvasData((d) => [...newCanvasData]);
      };
      const handleDragEnd = () => {
        guideLines.horizontal = null;
        guideLines.vertical = null;

        globalStatus.current.isDragingItem = false;

        setGuideLines({ ...guideLines });

        // newCanvasData = checkAxesAndCreateView(targetId, newCanvasData);
        setCanvasData([...setAllElemsUnselected(newCanvasData)]);

        document.removeEventListener("mousemove", handleDraging);
        document.removeEventListener("mouseup", handleDragEnd);
        document.removeEventListener("handpinching", handleDraging);
        document.removeEventListener("handpinchend", handleDragEnd);
      };

      document.addEventListener("mousemove", handleDraging);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("handpinching", handleDraging);
      document.addEventListener("handpinchend", handleDragEnd);
    },
    [canvasData, guideLines, setCanvasData, setGuideLines, globalStatus]
  );

  const handleAxisDrag = useCallback(
    (e) => {
      axisDragStart(e, id);
    },
    [axisDragStart, id]
  );

  const points = bbox?.reduce((total: number[], point: Point) => {
    total.push(point.x);
    total.push(point.y);
    return total;
  }, []);

  const istextCollided = checkTextCollision(textList.current);

  const isAxisForPie = checkAxisForPie(canvasData, id);

  const rotate = getAngle(startPos, endPos);

  useEffect(() => {
    const axisRef = axis.current;
    const startCircleRef = startTerminal.current;
    const endCircleRef = endTerminal.current;

    // @ts-ignore
    axisRef?.addEventListener("handdbpointing", handleClickAxis);
    // @ts-ignore
    axisRef?.addEventListener("handpinchstart", handleAxisDrag);
    // @ts-ignore
    startCircleRef?.addEventListener("handpinchstart", handleCircleDrag);
    // @ts-ignore
    endCircleRef?.addEventListener("handpinchstart", handleCircleDrag);

    return () => {
      // @ts-ignore
      axisRef?.removeEventListener("handdbpointing", handleClickAxis);
      // @ts-ignore
      // axisRef?.removeEventListener("handdbpointend", handleClickAxisEnd);
      // @ts-ignore
      axisRef?.removeEventListener("handpinchstart", handleAxisDrag);
      // @ts-ignore
      startCircleRef?.removeEventListener("handpinchstart", handleCircleDrag);
      // @ts-ignore
      endCircleRef?.removeEventListener("handpinchstart", handleCircleDrag);
    };
  }, [handleClickAxis, handleAxisDrag, handleCircleDrag, props, id]);

  const { translate } = getTicksTransform(rotate, istextCollided);
  // @ts-ignore
  // const { height: tlHeight, width: tlWidth } = textLabel.current?.getBoundingClientRect();
  return (
    <g
      style={{
        opacity: isAxisForPie ? 0.3 : 1,
      }}
    >
      <polygon
        ref={axis}
        id={id}
        fill={HIGHLIGHT_COLOR}
        fillOpacity={0}
        stroke={HIGHLIGHT_COLOR}
        strokeWidth={2}
        strokeOpacity={selectedState ? 1 : 0}
        strokeDasharray={3}
        //@ts-ignore
        points={points}
        style={{ cursor: "pointer" }}
        onClick={() => {
          // 如果模式全部关于 图表类型的 就进来
          if (isPlotForMode(mode)) {
            axisClick({
              id,
              label,
              type,
              data,
              scale,
              startPos,
              endPos,
              len,
            });
          }
        }}
        onMouseDown={(e) => {
          if (mode === MODE.SELECT) {
            axisDragStart(e, id);
          }
        }}
      ></polygon>
      <g
        className="axis"
        transform={`translate(${startPos.x}, ${startPos.y}) rotate(${getAngle(
          startPos,
          endPos
        )})`}
      >
        <g>
          <line
            className="axis-line"
            x1={0}
            y1={0}
            x2={len}
            y2={0}
            style={{
              stroke: "black",
              strokeWidth: 3,
            }}
          />
          {tickList &&
            tickList.map((tick: any, index: number) => {
              const { rotation, translate, textAnchor } = getTicksTransform(
                rotate,
                istextCollided
              );
              return (
                <g transform={`translate(${tick.x},0)`} key={index}>
                  <line
                    y2={10}
                    style={{
                      stroke: "#000",
                      strokeWidth: 3,
                    }}
                  ></line>
                  <text
                    ref={(text: any) => {
                      //@ts-ignore
                      textList.current[index] = text;
                    }}
                    transform={`translate(${translate[0]}, ${translate[1]}) rotate(${rotation})`}
                    fontSize={16}
                    textAnchor={textAnchor}
                    alignmentBaseline="middle"
                  >
                    {tick.text}
                  </text>
                </g>
              );
            })}
          <text
            x={len}
            y={0}
            transform={`translate(${translate[0]}, ${-translate[1]})`}
            fontSize="20px"
            ref={textLabel}
            // textAnchor={rotate === 180 ? "end" : "middle"}
            textAnchor={"end"}
            // @ts-ignore
            // transform={`translate(${
            //   rotate === 180 ? (len + 30) * 2 - 10 : 30
            // }, 0) rotate(${rotate === 180 ? -180 : 0})`}
            alignmentBaseline={translate[1] < 0 ? "hanging" : "middle"}
          >
            {label}
          </text>
        </g>
        <circle
          onMouseDown={(e) => {
            if (mode === MODE.SELECT) {
              circleDragStart(e, id, TERMINAL.START);
            }
          }}
          ref={startTerminal}
          id={"start" + id}
          className="start"
          cx={0}
          cy={0}
          r={TERMINAL_CIRCLE_RADIUS}
          fill={"#fff"}
          style={{
            stroke: startTerminalActive ? "#6ac1dc" : "black",
            strokeWidth: startTerminalActive ? 4 : 3,
          }}
        ></circle>
        <circle
          onMouseDown={(e) => {
            if (mode === MODE.SELECT) {
              circleDragStart(e, id, TERMINAL.END);
            }
          }}
          id={"end" + id}
          className="end"
          ref={endTerminal}
          cx={len}
          cy={0}
          r={TERMINAL_CIRCLE_RADIUS}
          fill={"#fff"}
          style={{
            stroke: endTerminalActive ? "#6ac1dc" : "black",
            strokeWidth: endTerminalActive ? 4 : 3,
          }}
        ></circle>
      </g>
    </g>
  );
});
