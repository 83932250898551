import "./index.css";
import { useCallback, useEffect, useRef } from "react";
import { adjustObjectPos } from "../../utils/canvas";
import { SCHEMA_ITEM_WIDTH } from "../constant";

export interface VisualMappingMenuProps<T> {
  /** 映射信息 */
  visualMappingInfo: T;
  /** 设置映射信息 */
  setVisualMappingInfo: (info: T | ((info: T) => T)) => void;
  /** 画布数据 */
  canvasData: Record<string, any>[];
}

export type VisualMappingInfo = {
  /** 环形菜单位置 */
  rMenuPos: number[]; // [0, 10]
  /** 线性数据维度菜单位置 */
  lMenuPos: number[];
  /** 视图 id: line、scatter、pie... */
  plotId: string;
  /** 菜单半径大小 */
  radius?: number;
  /** 环形菜单是否出现 */
  rMenuShow: boolean;
  /** 线性数据维度菜单是否出现 */
  lMenuShow: boolean;
  /** 视觉属性 */
  visualProp: string;
  /** 数据维度 */
  fieldName: string;
  /** 绘制环形菜单的内容 */
  rMenuInfo: any;
  /** 是否显示数据概览菜单 */
  dataOverviewMenuShow: boolean;
  /** 数据概览菜单位置 */
  dataOverviewMenuPos: number[];
};

export type VisualPropInfo = {
  /** 视觉属性名称 */
  propName: string; // eg: size
  /** 绘制菜单项目的 path 信息 */
  path: string; // 绘制菜单的 path d 信息
  /** 标题的位置 */
  textPos: number[];
  /** 是否被选中 */
  selected: boolean;
};

/** 计算线性菜单位置 */
function getLMenuPos(textPos: number[], rMenuPos: number[]) {
  // 判断 菜单项目是在右边还是左边
  const isLeft = textPos[0] < 0 ? true : false;
  const offset = isLeft ? -(SCHEMA_ITEM_WIDTH * 3 + 80) : 80; // todo: 360 为 lMenu 宽度
  // 考虑菜单上下左右边界问题
  const floatDataMenu = document.getElementById("float-data-menu");
  const originPos = {
    x: textPos[0] + rMenuPos[0] + offset,
    y: textPos[1] + rMenuPos[1] - 30,
  };
  const adjustedMenuPos = floatDataMenu
    ? adjustObjectPos(floatDataMenu, originPos)
    : originPos;

  return [adjustedMenuPos.x, adjustedMenuPos.y];
}

export const VisualMappingMenu: React.FC<
  VisualMappingMenuProps<VisualMappingInfo>
> = ({ visualMappingInfo, setVisualMappingInfo }) => {
  const { radius = 150, rMenuPos, rMenuShow, rMenuInfo } = visualMappingInfo;
  const visualPropsItems = useRef([]);

  const handleClick = useCallback(
    (e: any) => {
      const visualProp = e.target.getAttribute("data-name");
      const index = Number(e.target.getAttribute("data-index"));

      let visualPropsList = rMenuInfo;
      // 获维度菜单位置 visualTextPos + translate
      const textPos = visualPropsList[index].textPos;
      const lMenuPos = getLMenuPos(textPos, rMenuPos);
      visualPropsList = visualPropsList.map((d: any) => ({
        ...d,
        selected: false,
      }));
      visualPropsList[index].selected = true;

      // 弹出 数据维度 菜单
      setVisualMappingInfo((prev: VisualMappingInfo) => ({
        ...prev,
        lMenuPos,
        visualProp,
        lMenuShow: true,
        rMenuInfo: visualPropsList,
      }));
    },
    [setVisualMappingInfo, rMenuPos, rMenuInfo]
  );

  useEffect(() => {
    const doms = visualPropsItems.current;
    for (const dom of doms) {
      //@ts-ignore
      dom?.addEventListener("handpointing", handleClick);
    }
    return () => {
      for (const dom of doms) {
        //@ts-ignore
        dom?.removeEventListener("handpointing", handleClick);
      }
    };
  }, [handleClick, visualPropsItems]);

  // 菜单的位置
  const [x, y] = rMenuPos;
  const menuPadding = 65;
  return (
    <svg
      id="visual-mapping-menu"
      style={{
        visibility: rMenuShow ? "visible" : "hidden",
        position: "absolute",
      }}
      width={radius * 2 + menuPadding}
      height={radius * 2 + menuPadding}
      transform={`translate(${x - radius - menuPadding / 2}, ${
        y - radius - menuPadding / 2
      })`}
    >
      <defs>
        <filter id="gaussian">
          <feGaussianBlur in="SourceGraphic" stdDeviation={3}></feGaussianBlur>
        </filter>
      </defs>
      <g
        transform={`translate(${radius + menuPadding / 2}, ${
          radius + menuPadding / 2
        })`}
      >
        {visualMappingInfo.rMenuInfo?.map((datum: any, index: number) => {
          return (
            <g key={index}>
              <path
                d={datum.path}
                fill={"#85c1db"}
                fillOpacity={0.5}
                stroke={"#fff"}
                strokeWidth={2}
                filter={"url(#gaussian)"}
              />
              <path
                className="menu-path"
                d={datum.path}
                fill={datum.selected ? "#85c1db" : "#b7e7f7"}
                fillOpacity={0.8}
                stroke={"#fff"}
                strokeWidth={1}
                data-name={datum.propName}
                data-index={index}
                ref={(dom: any) => {
                  //@ts-ignore
                  visualPropsItems.current[index] = dom;
                }}
                onClick={(e) => {
                  handleClick(e);
                }}
              />
              <text
                fontSize="18px"
                fontWeight={800}
                className="menu-text"
                // stroke={"#fff"}
                // strokeWidth={"1px"}
                x={datum.textPos[0]}
                y={datum.textPos[1]}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {datum.propName}
              </text>
            </g>
          );
        })}
      </g>
    </svg>
  );
};
