import { vec3, vec2, mat3 } from "gl-matrix";
import { AxisInfo, Point } from "../../components/types";

/**
 * 获取变换矩阵
 */
export function getTransformMatrix(v1: AxisInfo, v2: AxisInfo) {
  // 剪切矩阵
  const shear_matrix = mat3.fromValues(
    (v1.endPos.x - v1.startPos.x) / v1.len,
    (v1.endPos.y - v1.startPos.y) / v1.len,
    0,
    (v2.endPos.x - v2.startPos.x) / v2.len,
    (v2.endPos.y - v2.startPos.y) / v2.len,
    0,
    0,
    0,
    1
  );

  // 正交矩阵
  const shear_matrix_invert = mat3.invert(mat3.create(), shear_matrix);

  // p 向量, b 向量
  const P = vec3.fromValues(
    v2.startPos.x - v1.startPos.x,
    v2.startPos.y - v1.startPos.y,
    1
  );
  const B = vec3.fromValues(
    v2.endPos.x - v2.startPos.x,
    v2.endPos.y - v2.startPos.y,
    1
  );

  // p，b 向量转换到正交空间
  const p_ortho = vec3.transformMat3(vec3.create(), P, shear_matrix_invert);
  const b_ortho = vec3.transformMat3(vec3.create(), B, shear_matrix_invert);

  // 正交空间的投影
  const pb_ortho_value =
    vec2.dot([p_ortho[0], p_ortho[1]], [b_ortho[0], b_ortho[1]]) /
    vec2.length([b_ortho[0], b_ortho[1]]);
  const pb_ortho = vec3.fromValues(0, pb_ortho_value, 1);

  // 转换回剪切空间
  const pb = vec3.transformMat3(vec3.create(), pb_ortho, shear_matrix);
  const firstTranslation = mat3.fromTranslation(mat3.create(), [
    v1.startPos.x,
    v1.startPos.y,
  ]);
  const secTranslation = mat3.fromTranslation(mat3.create(), [pb[0], pb[1]]);
  // const scale_matrix = mat3.fromScaling(mat3.create(), [(ax2 - ax1)/400,(axisVictor2_end[1] - axisVictor2_start[1])/300])

  return {
    shear_matrix,
    firstTranslation,
    secTranslation,
  };
}

/**
 * 获取最终变换后的坐标
 */
export function getSecTransVec3(
  points: number[],
  shear_matrix: mat3,
  firstTranslation: mat3,
  secTranslation: mat3
) {
  // 缩放
  // const scale2vec3 = vec3.transformMat3(vec3.create(), item, scale_matrix)
  // 切变
  const shear2vec3 = vec3.transformMat3(
    vec3.create(),
    points as any,
    shear_matrix
  );
  // 第一次平移
  const firstTransVec3 = vec3.transformMat3(
    vec3.create(),
    shear2vec3,
    firstTranslation
  );
  // 第二次平移
  const secTransVec3 = vec3.transformMat3(
    vec3.create(),
    firstTransVec3,
    secTranslation
  );

  return secTransVec3;
}

/* 算法来源：https://en.wikipedia.org/wiki/Centroid#Centroid_of_polygon */

// Contour object
export class Contour {
  constructor(points: any) {
    // @ts-ignore
    this.pts = points || []; // an array of Point objects defining the contour
  }

  area() {
    let area = 0;
    // @ts-ignore
    const pts = this.pts;
    const nPts = pts.length;
    let j = nPts - 1;
    let p1;
    let p2;

    for (let i = 0; i < nPts; j = i++) {
      p1 = pts[i];
      p2 = pts[j];
      area += p1.x * p2.y;
      area -= p1.y * p2.x;
    }
    area /= 2;

    return area;
  }

  centroid() {
    // @ts-ignore
    const pts = this.pts;
    const nPts = pts.length;
    let x = 0;
    let y = 0;
    let f;
    let j = nPts - 1;
    let p1;
    let p2;

    for (let i = 0; i < nPts; j = i++) {
      p1 = pts[i];
      p2 = pts[j];
      f = p1.x * p2.y - p2.x * p1.y;
      x += (p1.x + p2.x) * f;
      y += (p1.y + p2.y) * f;
    }

    f = this.area() * 6;

    return { x: x / f, y: y / f };
  }
}

/** 计算包围盒：通用 */
export function calBBox(arc: number, startPos: Point, rawPoints: number[][]) {
  const rotateMatrix = mat3.fromRotation(mat3.create(), arc);
  const translateMatrix = mat3.fromTranslation(mat3.create(), [
    startPos.x,
    startPos.y,
  ]);
  const transformPoints = rawPoints?.map((point: any) => {
    const rotate2vec3 = vec3.transformMat3(
      vec3.create(),
      point as any,
      rotateMatrix
    );
    return vec3.transformMat3(
      vec3.create(),
      rotate2vec3 as any,
      translateMatrix
    );
  });
  return transformPoints?.map((point: any) => ({ x: point[0], y: point[1] }));
}

/** 将包围盒数据转换成绘制 polygon 的数组 */
export function getPointsForPolygon(bbox: any[]) {
  return bbox
    ?.reduce((total: any[], point: any) => {
      if (point[0]) {
        total.push(point[0]);
        total.push(point[1]);
      } else {
        total.push(point.x);
        total.push(point.y);
      }
      return total;
    }, [])
    .join(",");
}
